import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class RecurringPlannedTransactionUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      categories: [],
      plannedTransactions: [this.createNewEmptyPlannedTransaction()],
      editedPlannedTransaction: this.createNewEmptyPlannedTransaction(),
      recurrences: [this.createNewEmptyRecurrence()],
      editedRecurrence: this.createNewEmptyRecurrence(),
      uploadInProgress: false,
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.RecurringPlannedTransactionUpload();
  }

  RecurringPlannedTransactionUpload() {
    this.api.listAccounts((accounts, error) => {
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else {
        if (this.CRUDTable.current) {
          this.CRUDTable.current.readyToInitializeEditedDataWithMetadata(accounts);
        }
        this.setState({ accounts: accounts });
      }
    });
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: " + error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  handleInitializeEditedPlannedTransactionWithAccountsRequest = (
    editedPlannedTransaction,
    accounts
  ) => {
    if (accounts.length > 0) {
      const firstAccount = accounts[0];
      return {
        ...editedPlannedTransaction,
        account_institution: editedPlannedTransaction.account_institution
          ? editedPlannedTransaction.account_institution
          : firstAccount.institution,
        account_currency: editedPlannedTransaction.account_currency
          ? editedPlannedTransaction.account_currency
          : firstAccount.currency,
        account_stock_symbol: editedPlannedTransaction.account_stock_symbol
          ? editedPlannedTransaction.account_stock_symbol
          : firstAccount.stock_symbol,
        account_type: editedPlannedTransaction.account_type
          ? editedPlannedTransaction.account_type
          : firstAccount.type,
        account_owner: editedPlannedTransaction.account_owner
          ? editedPlannedTransaction.account_owner
          : firstAccount.owner,
        account_name: editedPlannedTransaction.account_name
          ? editedPlannedTransaction.account_name
          : firstAccount.account_name,
      };
    } else {
      return editedPlannedTransaction;
    }
  };

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter((category) => {
        if (category1 === undefined) return true;
        else if (category2 === undefined)
          return category.category1 === category1 && category.category2;
        else
          return (
            category.category1 === category1 &&
            category.category2 === category2 &&
            category.category3
          );
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  createNewEmptyPlannedTransaction() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      description: "",
      amount: "",
      account_institution: "",
      account_currency: "",
      account_stock_symbol: "",
      account_type: "",
      account_name: "",
      account_owner: "",
      category1: "Follow-up",
      category2: "",
      category3: "",
    };
  }

  createNewEmptyRecurrence() {
    const now = new Date();
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    return {
      id: Math.random() * Number.MAX_VALUE,
      startDate: now.toISOString().split("T")[0],
      endDate: oneYearFromNow.toISOString().split("T")[0],
      type: "fixed_date",
      readableType: "Fixed Date",
      fixedDateDay: 1,
      fixedIntervalDays: 14,
    };
  }

  handleEditedPlannedTransactionChange = (plannedTransaction) => {
    this.setState({ editedPlannedTransaction: plannedTransaction });
  };

  handleEditedRecurrenceChange = (editedRecurrence) => {
    this.setState({ editedRecurrence: editedRecurrence });
  };

  handlePlannedTransactionUpload = () => {
    this.setState({
      uploadInProgress: true,
    });
    this.api.uploadRecurringPlannedTransaction(
      this.state.editedPlannedTransaction,
      this.state.editedRecurrence,
      (response, error) => {
        this.setState({
          uploadInProgress: false,
        });
        if (error) {
          alert("Failed to upload planned transaction: " + error.message);
        } else {
          this.setState({
            plannedTransactions: [this.createNewEmptyPlannedTransaction()],
            editedPlannedTransaction: this.createNewEmptyPlannedTransaction(),
            recurrences: [this.createNewEmptyRecurrence()],
            editedRecurrence: this.createNewEmptyRecurrence(),
          });
          this.props.onHide(true);
        }
      }
    );
  };

  render() {
    return (
      <Modal
        size='xl'
        show={this.props.show}
        onHide={() => {
          this.props.onHide(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Create new recurring planned transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                { title: "Start Date", sortingEnabled: false },
                { title: "End Date", sortingEnabled: false },
                { title: "Recurrence Type", sortingEnabled: false },
                { title: "Fixed Date Day", sortingEnabled: false },
                { title: "Fixed Interval Days", sortingEnabled: false },
              ],
              rows: [
                { propertyName: "startDate", inputType: "date", placeholder: "yyyy-mm-dd" },
                { propertyName: "endDate", inputType: "date", placeholder: "yyyy-mm-dd" },
                {
                  propertyName: "readableType",
                  inputType: "select",
                  options: ["Fixed Date", "Fixed Interval"],
                  onValueChanged: (newReadableTypeValue, editedRecurrence) => {
                    return {
                      ...editedRecurrence,
                      type: newReadableTypeValue === "Fixed Date" ? "fixed_date" : "fixed_interval",
                      readableType: newReadableTypeValue,
                    };
                  },
                },
                {
                  propertyName: "fixedDateDay",
                  valueFormatter: (value) => Math.round(value),
                  inputType: "number",
                  placeholder: "Fixed Date Day",
                },
                {
                  propertyName: "fixedIntervalDays",
                  valueFormatter: (value) => Math.round(value),
                  inputType: "number",
                  placeholder: "Fixed Interval Days",
                },
              ],
            }}
            data={this.state.recurrences}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedRecurrenceChange}
            loading={false}
            ref={this.CRUDTable}
          />
          <CRUDTable
            schema={{
              headers: [
                { title: "Description", sortingEnabled: false },
                { title: "Amount", sortingEnabled: false },
                { title: "Account", sortingEnabled: false },
                { title: "Category 1", sortingEnabled: false },
                { title: "Category 2", sortingEnabled: false },
                { title: "Category 3", sortingEnabled: false },
              ],
              rows: [
                { propertyName: "description", inputType: "text", placeholder: "Description" },
                {
                  propertyName: "amount",
                  valueFormatter: (value) => Math.round((value + Number.EPSILON) * 100) / 100,
                  inputType: "number",
                  placeholder: "Amount",
                },
                {
                  propertyName: "account_name",
                  inputType: "select",
                  options: this.state.accounts.map((account) => account.name),
                  onValueChanged: (newAccountValue, editedPlannedTransaction) => {
                    var newAccount;
                    this.state.accounts.forEach((account) => {
                      if (account.name === newAccountValue) {
                        newAccount = account;
                      }
                    });
                    return {
                      ...editedPlannedTransaction,
                      account_currency: newAccount.currency,
                      account_stock_symbol: newAccount.stock_symbol,
                      account_institution: newAccount.institution,
                      account_type: newAccount.type,
                      account_owner: newAccount.owner,
                      account_name: newAccount.name,
                    };
                  },
                },
                {
                  propertyName: "category1",
                  inputType: "select",
                  options: this.getCategoryValues(),
                  onValueChanged: (newCategory1Value, editedPlannedTransaction) => {
                    const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                    const newCategory3 = this.getCategoryValues(
                      newCategory1Value,
                      newCategory2 ? newCategory2 : ""
                    )[0];
                    return {
                      ...editedPlannedTransaction,
                      category1: newCategory1Value,
                      category2: newCategory2 ? newCategory2 : "",
                      category3: newCategory3 ? newCategory3 : "",
                    };
                  },
                },
                {
                  propertyName: "category2",
                  inputType: "select",
                  options: (editedData) => this.getCategoryValues(editedData.category1),
                  onValueChanged: (newCategory2Value, editedPlannedTransaction) => {
                    const newCategory3 = this.getCategoryValues(
                      editedPlannedTransaction.category1,
                      newCategory2Value
                    )[0];
                    return {
                      ...editedPlannedTransaction,
                      category2: newCategory2Value,
                      category3: newCategory3 ? newCategory3 : "",
                    };
                  },
                },
                {
                  propertyName: "category3",
                  inputType: "select",
                  options: (editedData) =>
                    this.getCategoryValues(editedData.category1, editedData.category2),
                },
              ],
            }}
            data={this.state.plannedTransactions}
            metadata={this.state.accounts}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedPlannedTransactionChange}
            onInitializeEditedDataWithMetadataRequested={
              this.handleInitializeEditedPlannedTransactionWithAccountsRequest
            }
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            disabled={this.state.uploadInProgress || this.state.accounts.length === 0}
            onClick={this.handlePlannedTransactionUpload}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
