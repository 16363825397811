import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class RateUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: [this.createNewEmptyRate()],
      editedRate: this.createNewEmptyRate(),
      uploadInProgress: false,
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  createNewEmptyRate() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      name: "",
      value: 0,
      currency: "",
      date: "2000-01-01",
    };
  }

  handleEditedRateChange = (rate) => {
    this.setState({ editedRate: rate });
  };

  handleRateUpload = () => {
    this.setState({
      uploadInProgress: true,
    });
    this.api.uploadCustomRate(this.state.editedRate, (response, error) => {
      this.setState({
        uploadInProgress: false,
      });
      if (error) {
        alert("Failed to upload rate: " + error.message);
      } else {
        this.setState({
          rates: [this.createNewEmptyRate()],
          editedRate: this.createNewEmptyRate(),
        });
        this.props.onHide(true);
      }
    });
  };

  render() {
    return (
      <Modal
        size="xl"
        show={this.props.show}
        onHide={() => {
          this.props.onHide(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create new rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                { title: "Name", sortingEnabled: false },
                { title: "Value", sortingEnabled: false },
                { title: "Currency", sortingEnabled: false },
                { title: "Date", sortingEnabled: false },
              ],
              rows: [
                { propertyName: "name", inputType: "text", placeholder: "Name" },
                { propertyName: "value", inputType: "number", placeholder: "Value" },
                {
                  propertyName: "currency",
                  inputType: "select",
                  placeholder: "Currency",
                  options: ["", "CAD", "USD", "EUR", "HUF"],
                },
                {
                  propertyName: "date",
                  inputType: "date",
                  placeholder: "yyyy-mm-dd",
                },
              ],
            }}
            data={this.state.rates}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedRateChange}
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            disabled={this.state.uploadInProgress}
            onClick={this.handleRateUpload}
          >
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
