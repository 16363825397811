import React, { Component } from "react";

export class CurrencyCell extends Component {
  formatBalanceString(value, currency) {
    if (isNaN(value)) {
      return "Error";
    }
    value = Math.round(value * 100) / 100;
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (currency === undefined) {
      return value;
    } else {
      switch (currency) {
        case "CAD":
          currency = "C$";
          break;
        case "HUF":
          currency = "Ft";
          break;
        case "USD":
          currency = "$";
          break;
        case "EUR":
          currency = "€";
          break;
        default:
          break;
      }
      return value + " " + currency;
    }
  }

  render() {
    return (
      <td key={this.props.id}>
        {this.formatBalanceString(this.props.value, this.props.currency)}
      </td>
    );
  }
}
