import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { PlannedTransactionsDateFilter } from "./PlannedTransactionsDateFilter";
import { PlannedTransactionsTextFilter } from "./PlannedTransactionsTextFilter";
import { CRUDTable } from "../shared/CRUDTable";
import { PageSizeSelector } from "../shared/PageSizeSelector";
import { Pagination } from "../shared/Pagination";

export class PlannedTransactionsTable extends Component {
  constructor(props) {
    super(props);
    var fromDate = new Date();
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + 365);
    this.state = {
      accounts: [],
      categories: [],
      plannedTransactions: [],
      pageSize: 50,
      pageCount: 0,
      selectedPage: 1,
      textFilter: "",
      dateSortAscendingDirection: false,
      fromDate: fromDate,
      toDate: toDate,
      refreshingPlannedTransactionsInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.PlannedTransactionsTable();
  }

  PlannedTransactionsTable() {
    this.api.listAccounts((accounts, error) => {
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else {
        this.setState({ accounts: accounts });
      }
    });
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: " + error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
    this.refreshPlannedTransactions();
  }

  formatDate(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
  }

  refreshPlannedTransactions(parameters = {}) {
    const initializedParameters = {
      pageSize: parameters.pageSize ? parameters.pageSize : this.state.pageSize,
      selectedPage: parameters.selectedPage ? parameters.selectedPage : this.state.selectedPage,
      textFilter: parameters.textFilter ? parameters.textFilter : this.state.textFilter,
      dateSortAscendingDirection: parameters.dateSortAscendingDirection
        ? parameters.dateSortAscendingDirection
        : this.state.dateSortAscendingDirection,
      fromDate: parameters.fromDate ? parameters.fromDate : this.state.fromDate,
      toDate: parameters.toDate ? parameters.toDate : this.state.toDate,
    };
    this.setState({
      refreshingPlannedTransactionsInProgress: true,
    });
    this.api.listPlannedTransactions(
      initializedParameters.pageSize,
      initializedParameters.selectedPage,
      initializedParameters.textFilter,
      initializedParameters.dateSortAscendingDirection,
      this.formatDate(initializedParameters.fromDate),
      this.formatDate(initializedParameters.toDate),
      (data, error) => {
        this.setState({
          refreshingPlannedTransactionsInProgress: false,
        });
        if (error) {
          alert("Failed to refresh planned transactions: " + error.message);
        } else {
          this.setState({
            plannedTransactions: data.plannedTransactions,
            pageCount: data.pageCount,
          });
        }
      }
    );
  }

  handleTextFilterChange = (textFilter) => {
    this.setState({ textFilter: textFilter });
    this.refreshPlannedTransactions({ textFilter: textFilter });
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize });
    this.refreshPlannedTransactions({ pageSize: pageSize });
  };

  handleSelectedPageChange = (selectedPage) => {
    this.setState({ selectedPage: selectedPage });
    this.refreshPlannedTransactions({ selectedPage: selectedPage });
    window.scrollTo(0, 0);
  };

  handlePlannedTransactionDelete = (plannedTransaction) => {
    var newPlannedTransactions = this.state.plannedTransactions;
    newPlannedTransactions.splice(
      this.state.plannedTransactions.findIndex((element) => element.id === plannedTransaction.id),
      1
    );
    this.setState({ plannedTransactions: newPlannedTransactions });
  };

  handlePlannedTransactionUpdate = (newPlannedTransaction) => {
    let plannedTransactionIndex = this.state.plannedTransactions.findIndex(
      (element) => element.id === newPlannedTransaction.id
    );
    var newPlannedTransactions = this.state.plannedTransactions;
    newPlannedTransactions[plannedTransactionIndex] = newPlannedTransaction;
    this.setState({ plannedTransactions: newPlannedTransactions });
  };

  handleDateSortDirectionChange = (dateSortAscendingDirection) => {
    this.setState({ dateSortAscendingDirection: dateSortAscendingDirection });
    this.refreshPlannedTransactions({ dateSortAscendingDirection: dateSortAscendingDirection });
  };

  handleFromDateChanged = (fromDate) => {
    let parsedFromDate = new Date(fromDate);
    this.setState({ fromDate: parsedFromDate });
    this.refreshPlannedTransactions({ fromDate: parsedFromDate });
  };

  handleToDateChanged = (toDate) => {
    let parsedToDate = new Date(toDate);
    this.setState({ toDate: parsedToDate });
    this.refreshPlannedTransactions({ toDate: parsedToDate });
  };

  handlePlannedTransactionDeleteRequest = (plannedTransaction, callback) => {
    if (window.confirm("Delete this planned transaction permanently?")) {
      this.api.deletePlannedTransaction(plannedTransaction.id, (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete planned transaction: " + error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handlePlannedTransactionUpdateRequest = (plannedTransaction, callback) => {
    this.api.updatePlannedTransaction(plannedTransaction, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update planned transaction: " + error.message);
      } else {
        callback(true);
      }
    });
  };

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter((category) => {
        if (category1 === undefined) return true;
        else if (category2 === undefined)
          return category.category1 === category1 && category.category2;
        else
          return (
            category.category1 === category1 &&
            category.category2 === category2 &&
            category.category3
          );
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  render() {
    return (
      <Container className="px-0">
        <Row className="my-3">
          <Col md={3}>
            <PlannedTransactionsTextFilter onTextFilterChanged={this.handleTextFilterChange} />
          </Col>
          <Col>
            <PlannedTransactionsDateFilter
              defaultFromDateValue={this.formatDate(this.state.fromDate)}
              defaultToDateValue={this.formatDate(this.state.toDate)}
              onFromDateChange={this.handleFromDateChanged}
              onToDateChange={this.handleToDateChanged}
            />
          </Col>
          <Col xs="auto">
            <PageSizeSelector
              defaultValue={this.state.pageSize}
              onPageSizeChange={this.handlePageSizeChange}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <CRUDTable
              schema={{
                headers: [
                  { title: "Date", sortingEnabled: true },
                  { title: "Description", sortingEnabled: false },
                  { title: "Amount", sortingEnabled: false },
                  { title: "Account", sortingEnabled: false },
                  { title: "Category 1", sortingEnabled: false },
                  { title: "Category 2", sortingEnabled: false },
                  { title: "Category 3", sortingEnabled: false },
                ],
                rows: [
                  { propertyName: "date", inputType: "date", placeholder: "yyyy-mm-dd" },
                  { propertyName: "description", inputType: "text", placeholder: "Description" },
                  {
                    propertyName: "amount",
                    valueFormatter: (value) => Math.round((value + Number.EPSILON) * 100) / 100,
                    inputType: "number",
                    placeholder: "Amount",
                  },
                  {
                    propertyName: "account_name",
                    inputType: "select",
                    options: this.state.accounts.map((account) => account.name),
                    onValueChanged: (newAccountValue, editedPlannedTransaction) => {
                      var newAccount;
                      this.state.accounts.forEach((account) => {
                        if (account.name === newAccountValue) {
                          newAccount = account;
                        }
                      });
                      return {
                        ...editedPlannedTransaction,
                        account_currency: newAccount.currency,
                        account_stock_symbol: newAccount.stock_symbol,
                        account_institution: newAccount.institution,
                        account_type: newAccount.type,
                        account_owner: newAccount.owner,
                        account_name: newAccount.name,
                      };
                    },
                  },
                  {
                    propertyName: "category1",
                    inputType: "select",
                    options: this.getCategoryValues(),
                    onValueChanged: (newCategory1Value, editedPlannedTransaction) => {
                      const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                      const newCategory3 = this.getCategoryValues(
                        newCategory1Value,
                        newCategory2 ? newCategory2 : ""
                      )[0];
                      return {
                        ...editedPlannedTransaction,
                        category1: newCategory1Value,
                        category2: newCategory2 ? newCategory2 : "",
                        category3: newCategory3 ? newCategory3 : "",
                      };
                    },
                  },
                  {
                    propertyName: "category2",
                    inputType: "select",
                    options: (editedData) => this.getCategoryValues(editedData.category1),
                    onValueChanged: (newCategory2Value, editedPlannedTransaction) => {
                      const newCategory3 = this.getCategoryValues(
                        editedPlannedTransaction.category1,
                        newCategory2Value
                      )[0];
                      return {
                        ...editedPlannedTransaction,
                        category2: newCategory2Value,
                        category3: newCategory3 ? newCategory3 : "",
                      };
                    },
                  },
                  {
                    propertyName: "category3",
                    inputType: "select",
                    options: (editedData) =>
                      this.getCategoryValues(editedData.category1, editedData.category2),
                  },
                ],
              }}
              data={this.state.plannedTransactions}
              actionsEnabled={true}
              editModeByDefault={false}
              onDateSortDirectionChanged={this.handleDateSortDirectionChange}
              onDataDeleteRequested={this.handlePlannedTransactionDeleteRequest}
              onDataUpdateRequested={this.handlePlannedTransactionUpdateRequest}
              onDataDeleted={this.handlePlannedTransactionDelete}
              onDataUpdated={this.handlePlannedTransactionUpdate}
              loading={this.state.refreshingPlannedTransactionsInProgress}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Pagination
              onSelectedPageChange={this.handleSelectedPageChange}
              pageCount={this.state.pageCount}
              className="ms-auto"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
