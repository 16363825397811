import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Login } from "./Login";
import { SignUp } from "./SignUp";
import { EmailConfirmation } from "./EmailConfirmation";
import { ForgotPassword } from "./ForgotPassword";
import { SetNewPassword } from "./SetNewPassword";

export class LoginAndSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailConfirmationModal: false,
      emailToBeConfirmed: "",
      passwordToBeUsedAfterConfirmation: "",
      loginErrorAfterEmailConfirmation: false,
      showForgotPasswordModal: false,
      showSetNewPasswordModal: false,
      showSuccessfulPasswordChangeConfirmation: false,
    };
  }

  handleEmailConfirmationModalClose = () => {
    this.setState({
      showEmailConfirmationModal: false,
    });
  };

  handleEmailVerificationRequired = (email, password) => {
    this.setState({
      showEmailConfirmationModal: true,
      emailToBeConfirmed: email,
      passwordToBeUsedAfterConfirmation: password,
    });
  };

  handleLoginError = () => {
    this.setState({
      loginErrorAfterEmailConfirmation: true,
    });
  };

  handleForgotPasswordButtonPressed = () => {
    this.setState({
      showForgotPasswordModal: true,
    });
  };

  handleSetNewPasswordModalClose = () => {
    this.setState({
      showSetNewPasswordModal: false,
    });
  };

  handleForgotPasswordRequestCompleted = (email) => {
    if (email === undefined) {
      this.setState({
        showForgotPasswordModal: false,
      });
    } else {
      this.setState({
        showSetNewPasswordModal: true,
        showForgotPasswordModal: false,
        emailToBeConfirmed: email,
      });
    }
  };

  handleSetNewPasswordCompleted = (success) => {
    this.setState({
      showSetNewPasswordModal: false,
      showSuccessfulPasswordChangeConfirmation: success,
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Login
              onEmailVerificationRequired={this.handleEmailVerificationRequired}
              loginErrorAfterEmailConfirmation={this.state.loginErrorAfterEmailConfirmation}
              onForgotPasswordButtonPressed={this.handleForgotPasswordButtonPressed}
              successfulPasswordChange={this.state.showSuccessfulPasswordChangeConfirmation}
            />
          </Col>
          <Col>
            <SignUp onEmailVerificationRequired={this.handleEmailVerificationRequired} />
          </Col>
        </Row>
        <EmailConfirmation
          show={this.state.showEmailConfirmationModal}
          onHide={this.handleEmailConfirmationModalClose}
          email={this.state.emailToBeConfirmed}
          password={this.state.passwordToBeUsedAfterConfirmation}
          onLoginError={this.handleLoginError}
        />
        <ForgotPassword
          show={this.state.showForgotPasswordModal}
          onForgotPasswordRequestCompleted={this.handleForgotPasswordRequestCompleted}
        />
        <SetNewPassword
          show={this.state.showSetNewPasswordModal}
          email={this.state.emailToBeConfirmed}
          onSetNewPasswordCompleted={this.handleSetNewPasswordCompleted}
        />
      </div>
    );
  }
}
