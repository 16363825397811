import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class TransactionUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      categories: [],
      transactions: [this.createNewEmptyTransaction()],
      editedTransaction: this.createNewEmptyTransaction(),
      uploadInProgress: false,
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.TransactionUpload();
  }

  TransactionUpload() {
    this.api.listAccounts((accounts, error) => {
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else {
        if (this.CRUDTable.current) {
          this.CRUDTable.current.readyToInitializeEditedDataWithMetadata(accounts);
        }
        this.setState({ accounts: accounts });
      }
    });
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: " + error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  handleInitializeEditedTransactionWithAccountsRequest = (editedTransaction, accounts) => {
    if (accounts.length > 0) {
      const firstAccount = accounts[0];
      return {
        ...editedTransaction,
        account_institution: editedTransaction.institution
          ? editedTransaction.institution
          : firstAccount.institution,
        account_currency: editedTransaction.currency
          ? editedTransaction.currency
          : firstAccount.currency,
        account_stock_symbol: editedTransaction.stock_symbol
          ? editedTransaction.stock_symbol
          : firstAccount.stock_symbol,
        account_type: editedTransaction.type ? editedTransaction.type : firstAccount.type,
        account_owner: editedTransaction.owner ? editedTransaction.owner : firstAccount.owner,
        account_name: editedTransaction.account_name
          ? editedTransaction.account_name
          : firstAccount.account_name,
      };
    } else {
      return editedTransaction;
    }
  };

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter((category) => {
        if (category1 === undefined) return true;
        else if (category2 === undefined)
          return category.category1 === category1 && category.category2;
        else
          return (
            category.category1 === category1 &&
            category.category2 === category2 &&
            category.category3
          );
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  createNewEmptyTransaction() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      date: "",
      description: "",
      amount: "",
      account_institution: "",
      account_currency: "",
      account_stock_symbol: "",
      account_type: "",
      account_name: "",
      account_owner: "",
      category1: "Follow-up",
      category2: "",
      category3: "",
    };
  }

  handleEditedTransactionChange = (transaction) => {
    this.setState({ editedTransaction: transaction });
  };

  handleTransactionUpload = () => {
    this.setState({
      uploadInProgress: true,
    });
    this.api.uploadTransaction(this.state.editedTransaction, (response, error) => {
      this.setState({
        uploadInProgress: false,
      });
      if (error) {
        alert("Failed to upload transaction: " + error.message);
      } else {
        this.setState({
          transactions: [this.createNewEmptyTransaction()],
          editedTransaction: this.createNewEmptyTransaction(),
        });
        this.props.onHide(true);
      }
    });
  };

  render() {
    return (
      <Modal
        size='xl'
        show={this.props.show}
        onHide={() => {
          this.props.onHide(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Create new transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                { title: "Date", sortingEnabled: true },
                { title: "Description", sortingEnabled: false },
                { title: "Amount", sortingEnabled: false },
                { title: "Account", sortingEnabled: false },
                { title: "Category 1", sortingEnabled: false },
                { title: "Category 2", sortingEnabled: false },
                { title: "Category 3", sortingEnabled: false },
              ],
              rows: [
                { propertyName: "date", inputType: "date", placeholder: "yyyy-mm-dd" },
                { propertyName: "description", inputType: "text", placeholder: "Description" },
                {
                  propertyName: "amount",
                  valueFormatter: (value) => Math.round((value + Number.EPSILON) * 100) / 100,
                  inputType: "number",
                  placeholder: "Amount",
                },
                {
                  propertyName: "account_name",
                  inputType: "select",
                  options: this.state.accounts.map((account) => account.name),
                  onValueChanged: (newAccountValue, editedTransaction) => {
                    var newAccount;
                    this.state.accounts.forEach((account) => {
                      if (account.name === newAccountValue) {
                        newAccount = account;
                      }
                    });
                    return {
                      ...editedTransaction,
                      account_currency: newAccount.currency,
                      account_stock_symbol: newAccount.stock_symbol,
                      account_institution: newAccount.institution,
                      account_type: newAccount.type,
                      account_owner: newAccount.owner,
                      account_name: newAccount.name,
                    };
                  },
                },
                {
                  propertyName: "category1",
                  inputType: "select",
                  options: this.getCategoryValues(),
                  onValueChanged: (newCategory1Value, editedTransaction) => {
                    const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                    const newCategory3 = this.getCategoryValues(
                      newCategory1Value,
                      newCategory2 ? newCategory2 : ""
                    )[0];
                    return {
                      ...editedTransaction,
                      category1: newCategory1Value,
                      category2: newCategory2 ? newCategory2 : "",
                      category3: newCategory3 ? newCategory3 : "",
                    };
                  },
                },
                {
                  propertyName: "category2",
                  inputType: "select",
                  options: (editedData) => this.getCategoryValues(editedData.category1),
                  onValueChanged: (newCategory2Value, editedTransaction) => {
                    const newCategory3 = this.getCategoryValues(
                      editedTransaction.category1,
                      newCategory2Value
                    )[0];
                    return {
                      ...editedTransaction,
                      category2: newCategory2Value,
                      category3: newCategory3 ? newCategory3 : "",
                    };
                  },
                },
                {
                  propertyName: "category3",
                  inputType: "select",
                  options: (editedData) =>
                    this.getCategoryValues(editedData.category1, editedData.category2),
                },
              ],
            }}
            data={this.state.transactions}
            metadata={this.state.accounts}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedTransactionChange}
            onInitializeEditedDataWithMetadataRequested={
              this.handleInitializeEditedTransactionWithAccountsRequest
            }
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            disabled={this.state.uploadInProgress || this.state.accounts.length === 0}
            onClick={this.handleTransactionUpload}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
