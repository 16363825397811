import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class AccountUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: [],
      accounts: [this.createNewEmptyAccount()],
      editedAccount: this.createNewEmptyAccount(),
      uploadInProgress: false
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.AccountUpload();
  }

  AccountUpload() {
    this.api.listCustomRates((rates, error) => {
      if (error) {
        alert("Failed to load rates: "+error.message);
      } else {
        this.setState({ rates: rates.map((rate) => rate.name) });
      }
    });
  }

  createNewEmptyAccount() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      institution: "",
      type: "",
      owner: "",
      currency: "",
      stock_symbol: "",
      category: ""
    };
  }

  handleEditedAccountChange = (account) => {
    this.setState({editedAccount: account});
  }

  handleAccountUpload = () => {
    this.setState({
      uploadInProgress: true
    });
    this.api.uploadAccount(this.state.editedAccount, (response, error) => {
      this.setState({
        uploadInProgress: false
      });
      if (error) {
        alert("Failed to upload account: "+error.message);
      } else {
        this.setState({
          accounts: [this.createNewEmptyAccount()],
          editedAccount: this.createNewEmptyAccount()
        });
        this.props.onHide(true);
      }
    });
  };

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={() => {this.props.onHide(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Create new account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                {title: "Institution", sortingEnabled: false},
                {title: "Type", sortingEnabled: false},
                {title: "Owner", sortingEnabled: false},
                {title: "Currency", sortingEnabled: false},
                {title: "Stock Symbol", sortingEnabled: false},
                {title: "Category", sortingEnabled: false},
              ],
              rows: [
                {propertyName: "institution", inputType: "text", placeholder: "Institution"},
                {propertyName: "type", inputType: "text", placeholder: "Type"},
                {propertyName: "owner", inputType: "text", placeholder: "Owner"},
                {propertyName: "currency", inputType: "select", placeholder: "Currency", options: ["", "CAD", "USD", "EUR", "HUF"]},
                {propertyName: "stock_symbol", inputType: "select", placeholder: "Stock Symbol", options: ["", "AMZN", "AAPL", "BRK.B", "IVV", "TSLA", "VWAPY"].concat(this.state.rates)},
                {propertyName: "category", inputType: "select", placeholder: "Category", options: ["", "Cash", "Credit", "Investment"]}
              ]}}
            data={this.state.accounts}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedAccountChange}
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={this.state.uploadInProgress} onClick={this.handleAccountUpload}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
