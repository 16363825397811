import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { AccountsTable } from "./AccountsTable";
import { AccountUpload } from "./AccountUpload";

export class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewAccountModal: false,
    };
    this.accountsTable = React.createRef();
  }

  handleCreateNewAccountModalOpen = () => {
    this.setState({
      showCreateNewAccountModal: true
    });
  }

  handleCreateNewAccountModalClose = (accountsRefreshed) => {
    this.setState({
      showCreateNewAccountModal: false
    });
    if (accountsRefreshed) {
      this.accountsTable.current.refreshAccounts();
    }
  }

  render() {
    return (
      <>
        <Container className="px-0">
          <Row>
            <Col>
              <DropdownButton title="Actions" align="end" className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewAccountModalOpen}>Create new account</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <AccountsTable ref={this.accountsTable}/>
            </Col>
          </Row>
        </Container>
        <AccountUpload
          show={this.state.showCreateNewAccountModal}
          onHide={this.handleCreateNewAccountModalClose}/>
      </>
    );
  }
}
