import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import config from "../config";
import { Cookies } from "react-cookie";

let instance = null;

const tokenCookieName = "financial-app-" + config.environment + "-auth-token";

export default class Authentication {
  // Constructor

  constructor() {
    this.cookies = new Cookies();
    const tokenCookie = this.cookies.get(tokenCookieName);
    if (tokenCookie !== undefined) {
      this.token = tokenCookie;
      this.isAuthenticated = true;
    } else {
      this.token = "";
      this.isAuthenticated = config.authenticate_in_development
        ? false
        : config.environment === "development";
    }
    this.authenticationCallback = () => {};
  }

  // Helper functions
  getCognitoUserPool() {
    var userPoolConfig = {
      UserPoolId: config.authentication_user_pool_id,
      ClientId: config.authentication_client_id,
    };
    if (config.environment === "development") {
      userPoolConfig.endpoint = "http://localhost:9229/";
    }
    return new CognitoUserPool(userPoolConfig);
  }

  getCognitoUser(email) {
    var cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.getCognitoUserPool(),
    });
    if (config.environment === "development") {
      cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
    }
    return cognitoUser;
  }

  // Auth methods

  authenticate(email, password, completion) {
    if (config.environment !== "development" || config.authenticate_in_development) {
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      let authenticationCallback = {
        onSuccess: (result) => {
          let token = result.getAccessToken().getJwtToken();
          this.token = token;
          this.cookies.set(tokenCookieName, token, {
            maxAge: 60 * 60, // 1 hour
          });
          this.isAuthenticated = true;
          this.authenticationCallback();
          completion();
        },
        onFailure: (error) => {
          completion(error);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.getCognitoUser(email).completeNewPasswordChallenge(
            authenticationData.Password,
            {},
            authenticationCallback
          );
        },
      };
      this.getCognitoUser(email).authenticateUser(authenticationDetails, authenticationCallback);
    } else {
      setTimeout(() => {
        const simulateFailure = false; // Set this to true to simulate login failure
        if (simulateFailure) {
          this.isAuthenticated = false;
          this.authenticationCallback();
          completion(new Error("Failed to login"));
        } else {
          this.cookies.set(tokenCookieName, "example", {
            maxAge: 60 * 60, // 1 hour
          });
          this.isAuthenticated = true;
          this.authenticationCallback();
          completion();
        }
      }, 1000);
    }
  }

  signUp(email, password, completion) {
    const attributeEmail = new CognitoUserAttribute({ Name: "email", Value: email });

    this.getCognitoUserPool().signUp(email, password, [attributeEmail], null, (error) => {
      completion(error);
    });
  }

  confirmUser(email, code, completion) {
    this.getCognitoUser(email).confirmRegistration(code, true, (error) => {
      completion(error);
    });
  }

  resendConfirmationCode(email, completion) {
    this.getCognitoUser(email).resendConfirmationCode((error) => {
      completion(error);
    });
  }

  forgotPassword(email, completion) {
    this.getCognitoUser(email).forgotPassword({
      onSuccess: (data) => completion(null),
      onFailure: (error) => {
        completion(error);
      },
    });
  }

  setNewPassword(code, password, email, completion) {
    this.getCognitoUser(email).confirmPassword(code, password, {
      onSuccess: (data) => completion(null),
      onFailure: (error) => {
        completion(error);
      },
    });
  }

  deleteToken() {
    this.token = "";
    this.cookies.remove(tokenCookieName);
    this.isAuthenticated = config.authenticate_in_development
      ? false
      : config.environment === "development";
    this.authenticationCallback();
  }

  // Singleton

  static getInstance() {
    if (!instance) instance = new Authentication();
    return instance;
  }
}
