import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Group from "react-bootstrap/FormGroup";
import Label from "react-bootstrap/FormLabel";
import Control from "react-bootstrap/FormControl";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class TransactionsUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionsFile: "",
      uploadInProgress: false
    }
    this.transactionsFileInput = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  handleTransactionsFileSelect = (event) => {
    this.setState({ transactionsFile: event.target.files[0] });
  };

  handleUploadTransactions = () => {
    this.setState({
      uploadInProgress: true
    });
    this.api.uploadTransactions(this.state.transactionsFile, (response, error) => {
      this.setState({
        uploadInProgress: false
      });
      if (error) {
        alert("Failed to upload transactions: "+error.message);
      } else {
        this.setState({
          transactionsFile: ""
        });
        this.transactionsFileInput.current.value = "";
        this.props.onHide(true);
      }
    });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={() => {this.props.onHide(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Upload transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Group className="mb-3" controlId='accountActivityFile'>
            <Label>Transactions file:</Label>
            <Control type='file' ref={this.transactionsFileInput} disabled={this.state.uploadInProgress} accept='.csv' onChange={this.handleTransactionsFileSelect} />
          </Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={this.state.uploadInProgress} onClick={this.handleUploadTransactions}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
