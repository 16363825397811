import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup'
import { BsSearch } from "react-icons/bs";

export class PlannedTransactionsTextFilter extends Component {
  handleTextFilterChange = (event) => {
    if (event.charCode === 13) {
      this.props.onTextFilterChanged(event.target.value);
    }
  };

  render() {
    return (
      <Form.Group className='search-bar' controlId='searchBar'>
        <InputGroup>
          <InputGroup.Text>
            <BsSearch icon='search' />
          </InputGroup.Text>
          <Form.Control
            type='text'
            placeholder='Search by text'
            onKeyPress={this.handleTextFilterChange}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
