import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class BatchTransactionUpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      transactions: [this.createNewEmptyTransaction()],
      editedTransaction: this.createNewEmptyTransaction()
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: "+error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  handleInitializeEditedTransactionWithAccountsRequest = (editedTransaction, accounts) => {
    const firstAccount = accounts[0];
    return {
      ...editedTransaction,
      account_institution: editedTransaction.institution ? editedTransaction.institution : firstAccount.institution,
      account_currency: editedTransaction.currency ? editedTransaction.currency : firstAccount.currency,
      account_stock_symbol: editedTransaction.stock_symbol ? editedTransaction.stock_symbol : firstAccount.stock_symbol,
      account_type: editedTransaction.type ? editedTransaction.type : firstAccount.type,
      account_owner: editedTransaction.owner ? editedTransaction.owner : firstAccount.owner,
      account_name: editedTransaction.account_name ? editedTransaction.account_name : firstAccount.account_name
    };
  }

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter(
        (category) => {
        if (category1 === undefined) return true
        else if (category2 === undefined) return category.category1 === category1 && category.category2;
        else return category.category1 === category1 && category.category2 === category2 && category.category3;
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  createNewEmptyTransaction() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      description: "",
      category1: "Follow-up",
      category2: "",
      category3: ""
    };
  }

  handleEditedTransactionChange = (transaction) => {
    this.setState({editedTransaction: transaction});
  }

  handleUpdateButtonClick = () => {
    var newEditedTransaction = this.state.editedTransaction;
    newEditedTransaction.description = newEditedTransaction.description ? newEditedTransaction.description : undefined;
    this.props.onHide(newEditedTransaction);
  }

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={() => {this.props.onHide()}}>
        <Modal.Header closeButton>
          <Modal.Title>Update transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                {title: "Description", sortingEnabled: false},
                {title: "Category 1", sortingEnabled: false},
                {title: "Category 2", sortingEnabled: false},
                {title: "Category 3", sortingEnabled: false},
              ],
              rows: [
                {propertyName: "description", inputType: "text", placeholder: "Description"},
                {
                  propertyName: "category1",
                  inputType: "select",
                  options: this.getCategoryValues(),
                  onValueChanged: (newCategory1Value, editedTransaction) => {
                    const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                    const newCategory3 = this.getCategoryValues(newCategory1Value, newCategory2 ? newCategory2 : "")[0];
                    return {
                      ...editedTransaction,
                      category1: newCategory1Value,
                      category2: newCategory2 ? newCategory2 : "",
                      category3: newCategory3 ? newCategory3 : ""
                    };
                  }
                },
                {
                  propertyName: "category2",
                  inputType: "select",
                  options: (editedData) => this.getCategoryValues(editedData.category1),
                  onValueChanged: (newCategory2Value, editedTransaction) => {
                    const newCategory3 = this.getCategoryValues(editedTransaction.category1, newCategory2Value)[0];
                    return {
                      ...editedTransaction,
                      category2: newCategory2Value,
                      category3: newCategory3 ? newCategory3 : ""
                    };
                  }
                },
                {propertyName: "category3", inputType: "select", options: (editedData) => this.getCategoryValues(editedData.category1, editedData.category2)}
              ]}}
            data={this.state.transactions}
            metadata={this.state.accounts}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedTransactionChange}
            onInitializeEditedDataWithMetadataRequested={this.handleInitializeEditedTransactionWithAccountsRequest}
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' onClick={this.handleUpdateButtonClick}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
