import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SummaryDateFilter } from "./SummaryDateFilter";
import { SummaryCurrencyFilter } from "./SummaryCurrencyFilter";

export class SummaryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFromDateChanged = (fromDate) => {
    this.props.onFromDateChange(fromDate);
  };

  handleToDateChanged = (toDate) => {
    this.props.onToDateChange(toDate);
  };

  handleCurrencyChanged = (currency) => {
    this.props.onCurrencyChange(currency);
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <SummaryDateFilter
              defaultFromDateValue={this.props.defaultFromDateValue}
              defaultToDateValue={this.props.defaultToDateValue}
              onFromDateChange={this.handleFromDateChanged}
              onToDateChange={this.handleToDateChanged}
            />
          </Col>
          <Col>
            <SummaryCurrencyFilter
              defaultValue={this.props.defaultCurrencyValue}
              onCurrencyChange={this.handleCurrencyChanged}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
