import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { TransactionsTable } from "./TransactionsTable";
import { TransactionUpload } from "./TransactionUpload";
import { TransactionsUpload } from "./TransactionsUpload";
import { AccountActivityUpload } from "./AccountActivityUpload";
import { AccountActivityUploadReview } from "./AccountActivityUploadReview";
import { BatchTransactionUpdateModal } from "./BatchTransactionUpdateModal";

export class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewTransactionModal: false,
      showUploadTransactionsModal: false,
      showUploadAccountActivityModal: false,
      showUploadAccountActivityReviewModal: false,
      processedTransactions: [],
      showBatchTransactionUpdateModal: false,
    };
    this.transactionsTable = React.createRef();
  }

  handleCreateNewTransactionModalOpen = () => {
    this.setState({
      showCreateNewTransactionModal: true,
    });
  };

  handleUploadTransactionsModalOpen = () => {
    this.setState({
      showUploadTransactionsModal: true,
    });
  };

  handleUploadAccountActivityModalOpen = () => {
    this.setState({
      showUploadAccountActivityModal: true,
    });
  };

  handleBatchTransactionUpdateModalOpen = () => {
    this.setState({
      showBatchTransactionUpdateModal: true,
    });
  };

  handleCreateNewTransactionModalClose = (transactionsRefreshed) => {
    this.setState({
      showCreateNewTransactionModal: false,
    });
    if (transactionsRefreshed) {
      this.transactionsTable.current.refreshTransactions();
    }
  };

  handleUploadTransactionsModalClose = (transactionsRefreshed) => {
    this.setState({
      showUploadTransactionsModal: false,
    });
    if (transactionsRefreshed) {
      this.transactionsTable.current.refreshTransactions();
    }
  };

  handleUploadAccountActivityModalClose = () => {
    this.setState({
      showUploadAccountActivityModal: false,
    });
  };

  handleAccountActivityTransactionsForReviewReceive = (processedTransactions) => {
    this.setState({
      showUploadAccountActivityModal: false,
      showUploadAccountActivityReviewModal: true,
      processedTransactions: processedTransactions.map((processedTransaction) => {
        processedTransaction.id = Math.random() * 100000000;
        return processedTransaction;
      }),
    });
  };

  handleUploadAccountActivityReviewModalClose = (transactionsRefreshed) => {
    this.setState({
      showUploadAccountActivityReviewModal: false,
    });
    if (transactionsRefreshed) {
      this.transactionsTable.current.refreshTransactions();
    }
  };

  handleBatchTransactionUpdateModalClose = (newTransactionParameters) => {
    this.setState({
      showBatchTransactionUpdateModal: false,
    });
    if (newTransactionParameters) {
      this.transactionsTable.current.handleBatchTransactionUpdateRequest(newTransactionParameters);
    }
  };

  handleBatchTransactionDeleteRequest = () => {
    this.transactionsTable.current.handleBatchTransactionDeleteRequest();
  };

  render() {
    return (
      <>
        <Container className='px-0'>
          <Row>
            <Col>
              <DropdownButton title='Actions' align='end' className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewTransactionModalOpen}>
                  Create new transaction
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleUploadTransactionsModalOpen}>
                  Upload transactions from file
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleUploadAccountActivityModalOpen}>
                  Import transactions from account activity
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleBatchTransactionUpdateModalOpen}>
                  Update selected transactions
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleBatchTransactionDeleteRequest}>
                  Delete selected transactions
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className='my-3'>
            <Col>
              <TransactionsTable ref={this.transactionsTable} />
            </Col>
          </Row>
        </Container>
        <TransactionUpload
          show={this.state.showCreateNewTransactionModal}
          onHide={this.handleCreateNewTransactionModalClose}
        />
        <TransactionsUpload
          show={this.state.showUploadTransactionsModal}
          onHide={this.handleUploadTransactionsModalClose}
        />
        <AccountActivityUpload
          show={this.state.showUploadAccountActivityModal}
          onHide={this.handleUploadAccountActivityModalClose}
          onAccountActivityTransactionsForReviewReceived={
            this.handleAccountActivityTransactionsForReviewReceive
          }
        />
        <AccountActivityUploadReview
          show={this.state.showUploadAccountActivityReviewModal}
          onHide={this.handleUploadAccountActivityReviewModalClose}
          processedTransactions={this.state.processedTransactions}
        />
        <BatchTransactionUpdateModal
          show={this.state.showBatchTransactionUpdateModal}
          onHide={this.handleBatchTransactionUpdateModalClose}
        />
      </>
    );
  }
}
