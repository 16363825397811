import React, { Component } from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";

const PREVIOUS_PAGE = "PREVIOUS_PAGE";
const NEXT_PAGE = "NEXT_PAGE";
const ELLIPSIS = "ELLIPSIS";
const ELLIPSIS2 = "ELLIPSIS2";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: 1,
    };
  }

  handlePageClick = (event) => {
    let selectedPage = parseInt(event.target.text);
    this.setState({
      selectedPage: selectedPage,
    });
    this.props.onSelectedPageChange(selectedPage);
  };

  handlePreviousPageClick = () => {
    let previousPage = this.state.selectedPage - 1;
    this.setState({
      selectedPage: previousPage,
    });
    this.props.onSelectedPageChange(previousPage);
  };

  handleNextPageClick = () => {
    let nextPage = this.state.selectedPage + 1;
    this.setState({
      selectedPage: nextPage,
    });
    this.props.onSelectedPageChange(nextPage);
  };

  createPageNumbers = () => {
    const totalPages = this.props.pageCount;
    const currentPage = this.state.selectedPage;
    const pageNeighbours = 2;

    const totalNumbers = pageNeighbours * 2 + 1;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [PREVIOUS_PAGE, 1, ELLIPSIS, ...extraPages, ...pages, totalPages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [1, ...pages, ...extraPages, ELLIPSIS, totalPages, NEXT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [PREVIOUS_PAGE, 1, ELLIPSIS, ...pages, ELLIPSIS2, totalPages, NEXT_PAGE];
          break;
        }
      }

      return [...pages];
    }

    return range(1, totalPages);
  };

  render() {
    if (this.props.pageCount === 1) return null;
    const pages = this.createPageNumbers();
    return (
      <BootstrapPagination>
        {pages.map((page, pageIndex) => {
          if (page === PREVIOUS_PAGE)
            return <BootstrapPagination.Prev key={page} onClick={this.handlePreviousPageClick} />;
          else if (page === NEXT_PAGE)
            return <BootstrapPagination.Next key={page} onClick={this.handleNextPageClick} />;
          else if (page === ELLIPSIS || page === ELLIPSIS2)
            return <BootstrapPagination.Ellipsis key={page} disabled />;
          else
            return (
              <BootstrapPagination.Item
                key={page}
                active={this.state.selectedPage === page}
                onClick={this.handlePageClick}>
                {page}
              </BootstrapPagination.Item>
            );
        })}
      </BootstrapPagination>
    );
  }
}
