import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";

export class SummaryDateFilter extends Component {
  handleFromDateChanged = (event) => {
    this.props.onFromDateChange(event.target.value);
  };

  handleToDateChanged = (event) => {
    this.props.onToDateChange(event.target.value);
  };

  render() {
    return (
      <Form>
        <Row>
          <Col xxl="auto">
            <Form.Group as={Row} controlId="fromDate">
              <Form.Label column>From:</Form.Label>
              <Col>
                <Form.Control
                  type="date"
                  placeholder="From date"
                  defaultValue={this.props.defaultFromDateValue}
                  onChange={this.handleFromDateChanged}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xxl="auto">
            <Form.Group as={Row} controlId="toDate">
              <Form.Label column>To:</Form.Label>
              <Col>
                <Form.Control
                  type="date"
                  placeholder="To date"
                  defaultValue={this.props.defaultToDateValue}
                  onChange={this.handleToDateChanged}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  }
}
