import React from "react";
import ReactDOM from "react-dom";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "./components/layout/Layout";
import { Summary } from "./components/summary/Summary";
import { Transactions } from "./components/transactions/Transactions";
import { PlannedTransactions } from "./components/planned_transactions/PlannedTransactions";
import { Patterns } from "./components/patterns/Patterns";
import { Accounts } from "./components/accounts/Accounts";
import { Categories } from "./components/categories/Categories";
import { Rates } from "./components/rates/Rates";
import { FollowUpManagement } from "./components/follow_up_management/FollowUpManagement";
import { AccountActivityMappings } from "./components/account_activity_mappings/AccountActivityMappings";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Summary />} />
        <Route path='/transactions' element={<Transactions />} />
        <Route path='/plannedTransactions' element={<PlannedTransactions />} />
        <Route path='/patterns' element={<Patterns />} />
        <Route path='/accounts' element={<Accounts />} />
        <Route path='/categories' element={<Categories />} />
        <Route path='/rates' element={<Rates />} />
        <Route path='/followUpManagement' element={<FollowUpManagement />} />
        <Route path='/accountActivityMappings' element={<AccountActivityMappings />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById("app")
);
