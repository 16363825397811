import React, { Component } from "react";
import { Modal, Button, Container, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Group from "react-bootstrap/FormGroup";
import Label from "react-bootstrap/FormLabel";
import Control from "react-bootstrap/FormControl";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class AccountActivityUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      account: "",
      accountActivityMappings: [],
      accountActivityMapping: "",
      accountActivityFile: "",
      submitInProgress: false,
      accountActivityMappingsLoadingInProgress: true,
      accountsLoadingInProgress: true,
      latestTransactionDates: [],
    };
    this.accountActivityFileInput = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.api.listAccounts((accounts, error) => {
      var newState = {
        accountsLoadingInProgress: false,
      };
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else if (accounts.length > 0) {
        let firstAccount = accounts[0];
        newState = {
          ...newState,
          accounts: accounts,
          account: firstAccount,
        };
      }
      this.setState(newState);
    });
    this.api.listAccountActivityMappings((accountActivityMappings, error) => {
      var newState = {
        accountActivityMappingsLoadingInProgress: false,
      };
      if (error) {
        alert("Failed to list account activity mappings: " + error.message);
      } else if (accountActivityMappings.length > 0) {
        let firstAccountActivityMapping = accountActivityMappings[0];
        newState = {
          ...newState,
          accountActivityMappings: accountActivityMappings,
          accountActivityMapping: firstAccountActivityMapping,
        };
      }
      this.setState(newState);
    });
    this.api.listLatestTransactionDates((latestTransactionDates, error) => {
      if (error) {
        alert("Failed to list latest transaction dates: " + error.message);
      } else if (latestTransactionDates.length > 0) {
        this.setState({
          latestTransactionDates: latestTransactionDates,
        });
      }
    });
  }

  handleAccountActivityFileSelect = (event) => {
    this.setState({ accountActivityFile: event.target.files[0] });
  };

  handleAccountChange = (event) => {
    var selectedAccount;
    this.state.accounts.forEach((account) => {
      if (account.name === event.target.value) {
        selectedAccount = account;
      }
    });
    this.setState({
      account: selectedAccount,
    });
  };

  handleAccountActivityMappingChange = (event) => {
    var selectedAccountActivityMapping;
    this.state.accountActivityMappings.forEach((accountActivityMapping) => {
      if (accountActivityMapping.account_name === event.target.value) {
        selectedAccountActivityMapping = accountActivityMapping;
      }
    });
    this.setState({
      accountActivityMapping: selectedAccountActivityMapping,
    });
  };

  handleSubmitAccountActivityForReview = () => {
    this.setState({
      submitInProgress: true,
    });
    this.api.processAccountActivity(
      this.state.account,
      this.state.accountActivityMapping,
      this.state.accountActivityFile,
      (processedTransactions, error) => {
        this.setState({
          submitInProgress: false,
        });
        if (error) {
          alert("Failed to process account activity: " + error.message);
        } else {
          let firstAccount = this.state.accounts[0];
          this.setState({
            account: firstAccount,
            accountActivityFile: "",
          });
          this.accountActivityFileInput.current.value = "";
          this.props.onAccountActivityTransactionsForReviewReceived(processedTransactions);
        }
      }
    );
  };

  render() {
    var accounts = (
      <option key="loading" value="Loading...">
        Loading...
      </option>
    );
    if (!this.state.accountsLoadingInProgress) {
      if (this.state.accounts.length > 0) {
        accounts = this.state.accounts.map((account, accountIndex) => {
          return (
            <option key={accountIndex} value={account.name}>
              {account.name}
            </option>
          );
        });
      } else {
        accounts = (
          <option key="no_accounts" value="No accounts">
            No accounts
          </option>
        );
      }
    }

    var accountActivityMappings = (
      <option key="loading" value="Loading...">
        Loading...
      </option>
    );
    if (!this.state.accountActivityMappingsLoadingInProgress) {
      if (this.state.accountActivityMappings.length > 0) {
        accountActivityMappings = this.state.accountActivityMappings.map(
          (accountActivityMapping, accountActivityMappingIndex) => {
            return (
              <option key={accountActivityMappingIndex} value={accountActivityMapping.account_name}>
                {accountActivityMapping.account_name}
              </option>
            );
          }
        );
      } else {
        accountActivityMappings = (
          <option key="no_account_activity_mappings" value="No account activity mappings">
            No account activity mappings
          </option>
        );
      }
    }

    var latestTransactionDates = this.state.latestTransactionDates.map(
      (latestTransactionDate, index) => (
        <tr key={index}>
          <td>{latestTransactionDate.account.name}</td>
          <td>{latestTransactionDate.latestTransactionDate}</td>
        </tr>
      )
    );

    let formDisabled =
      this.state.accounts.length === 0 ||
      this.state.accountActivityMappings.length === 0 ||
      this.state.uploadInProgress;
    return (
      <Container>
        <Modal
          show={this.props.show}
          onHide={() => {
            this.props.onHide(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload account activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Group className="mb-3" controlId="accountActivityFile">
                <Label>Account activity file:</Label>
                <Control
                  type="file"
                  ref={this.accountActivityFileInput}
                  disabled={formDisabled}
                  accept=".csv"
                  onChange={this.handleAccountActivityFileSelect}
                />
              </Group>
              <Group className="mb-3" controlId="account">
                <Label>Account:</Label>
                <Control
                  as="select"
                  disabled={formDisabled}
                  value={this.state.account.name}
                  onChange={this.handleAccountChange}
                >
                  {accounts}
                </Control>
              </Group>
              <Group className="mb-3" controlId="accountActivityMapping">
                <Label>Account activity mapping:</Label>
                <Control
                  as="select"
                  disabled={formDisabled}
                  value={this.state.accountActivityMapping.account_name}
                  onChange={this.handleAccountActivityMappingChange}
                >
                  {accountActivityMappings}
                </Control>
              </Group>
            </Form>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Account name</th>
                  <th>Last uploaded transaction</th>
                </tr>
              </thead>
              <tbody>{latestTransactionDates}</tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={formDisabled}
              onClick={this.handleSubmitAccountActivityForReview}
            >
              {this.state.submitInProgress ? "Submitting..." : "Submit for review"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
