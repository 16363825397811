import React, { Component } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import Authentication from "../../authentication/Authentication";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordEmail: "",
      forgotPasswordRequestError: {},
      forgotPasswordRequestInProgress: false,
    };
    this.auth = Authentication.getInstance();
  }

  handleSubmitButtonClicked = () => {
    this.setState({
      forgotPasswordRequestInProgress: true,
    });
    this.auth.forgotPassword(this.state.forgotPasswordEmail, (error) => {
      this.setState({
        forgotPasswordRequestError: error,
        forgotPasswordRequestInProgress: false,
      });
      if (!error) {
        this.props.onForgotPasswordRequestCompleted(this.state.forgotPasswordEmail);
      }
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      forgotPasswordEmail: event.target.value,
    });
  };

  render() {
    return (
      <Modal
        size='md'
        show={this.props.show}
        onHide={() => {
          this.props.onForgotPasswordRequestCompleted();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Request new password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='forgotPasswordEmail'>
            <Form.Label>Please enter the email address used with your account</Form.Label>
            <Form.Control
              name='forgotPasswordEmail'
              placeholder='Email address'
              onChange={this.handleEmailChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            disabled={this.state.forgotPasswordRequestInProgress}
            onClick={
              !this.state.forgotPasswordRequestInProgress ? this.handleSubmitButtonClicked : null
            }>
            {this.state.forgotPasswordRequestInProgress ? "Sending request..." : "Send request"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
