import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export class PageSizeSelector extends Component {
  handlePageSizeChanged = (event) => {
    this.props.onPageSizeChange(event.target.value);
  };

  render() {
    return (
      <Form>
        <Form.Group as={Row} controlId='pageSize'>
          <Form.Label column>Page size:</Form.Label>
          <Col>
            <Form.Select
              className='w-auto'
              defaultValue={this.props.defaultValue}
              onChange={this.handlePageSizeChanged}>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>
    );
  }
}
