import React, { Component } from "react";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { CRUDTable } from "../shared/CRUDTable";

export class PatternsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      patterns: [],
      categories: [],
      refreshingPatternsInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.PatternsTable();
  }

  PatternsTable() {
    this.refreshPatterns();
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: "+error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  refreshPatterns() {
    this.setState({
      refreshingPatternsInProgress: true,
    });
    this.api.listPatterns((patterns, error) => {
      this.setState({
        refreshingPatternsInProgress: false,
      });
      if (error) {
        alert("Failed to load patterns: "+error.message);
      } else {
        this.setState({ patterns: patterns });
      }
    });
  }

  handlePatternDeleteRequest = (pattern, callback) => {
    if (window.confirm("Delete this pattern permanently?")) {
      this.api.deletePattern(pattern.id, (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete pattern: "+error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handlePatternUpdateRequest = (pattern, callback) => {
    this.api.updatePattern(pattern, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update pattern: "+error.message);
      } else {
        callback(true);
      }
    });
  };

  handlePatternDelete = (pattern) => {
    var newPatterns = this.state.patterns;
    newPatterns.splice(
      this.state.patterns.findIndex((element) => element.id === pattern.id),
      1
    );
    this.setState({ patterns: newPatterns });
  };

  handlePatternUpdate = (newPattern) => {
    var newPatterns = this.state.patterns;
    let patternIndex = this.state.patterns.findIndex((element) => element.id === newPattern.id);
    newPatterns[patternIndex] = newPattern;
    this.setState({ patterns: newPatterns });
  };

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter(
        (category) => {
        if (category1 === undefined) return true
        else if (category2 === undefined) return category.category1 === category1 && category.category2;
        else return category.category1 === category1 && category.category2 === category2 && category.category3;
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  render() {
    return (
      <CRUDTable
        schema={{
          headers: [
            {title: "Pattern", sortingEnabled: false},
            {title: "Description", sortingEnabled: false},
            {title: "Category 1", sortingEnabled: false},
            {title: "Category 2", sortingEnabled: false},
            {title: "Category 3", sortingEnabled: false},
          ],
          rows: [
            {propertyName: "pattern", inputType: "text", placeholder: "Pattern"},
            {propertyName: "description", inputType: "text", placeholder: "Description"},
            {
              propertyName: "category1",
              inputType: "select",
              options: this.getCategoryValues(),
              onValueChanged: (newCategory1Value, editedPattern) => {
                const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                const newCategory3 = this.getCategoryValues(newCategory1Value, newCategory2 ? newCategory2 : "")[0];
                return {
                  ...editedPattern,
                  category1: newCategory1Value,
                  category2: newCategory2 ? newCategory2 : "",
                  category3: newCategory3 ? newCategory3 : ""
                };
              }
            },
            {
              propertyName: "category2",
              inputType: "select",
              options: (editedData) => this.getCategoryValues(editedData.category1),
              onValueChanged: (newCategory2Value, editedPattern) => {
                const newCategory3 = this.getCategoryValues(editedPattern.category1, newCategory2Value)[0];
                return {
                  ...editedPattern,
                  category2: newCategory2Value,
                  category3: newCategory3 ? newCategory3 : ""
                };
              }
            },
            {propertyName: "category3", inputType: "select", options: (editedData) => this.getCategoryValues(editedData.category1, editedData.category2)}
          ]}}
        data={this.state.patterns}
        actionsEnabled={true}
        editModeByDefault={false}
        onDataDeleteRequested={this.handlePatternDeleteRequest}
        onDataUpdateRequested={this.handlePatternUpdateRequest}
        onDataDeleted={this.handlePatternDelete}
        onDataUpdated={this.handlePatternUpdate}
        loading={this.state.refreshingPatternsInProgress}
      />
    );
  }
}
