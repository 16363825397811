import React, { Component } from "react";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { CRUDTable } from "../shared/CRUDTable";

export class AccountsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      rates: [],
      refreshingAccountsInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.api.listCustomRates((rates, error) => {
      if (error) {
        alert("Failed to load rates: " + error.message);
      } else {
        this.setState({ rates: rates.map((rate) => rate.name) });
      }
    });
    this.refreshAccounts();
  }

  refreshAccounts() {
    this.setState({
      refreshingAccountsInProgress: true,
    });
    this.api.listAccounts((accounts, error) => {
      this.setState({
        refreshingAccountsInProgress: false,
      });
      if (error) {
        alert("Failed to load accounts: " + error.message);
      } else {
        this.setState({ accounts: accounts });
      }
    });
  }

  handleAccountDeleteRequest = (account, callback) => {
    if (window.confirm("Delete this account permanently?")) {
      this.api.deleteAccount(account.id, (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete account: " + error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handleAccountUpdateRequest = (account, callback) => {
    this.api.updateAccount(account, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update account: " + error.message);
      } else {
        callback(true);
      }
    });
  };

  handleAccountDelete = (account) => {
    var newAccounts = this.state.accounts;
    newAccounts.splice(
      this.state.accounts.findIndex((element) => element.id === account.id),
      1
    );
    this.setState({ accounts: newAccounts });
  };

  handleAccountUpdate = (newAccount) => {
    var newAccounts = this.state.accounts;
    let accountIndex = this.state.accounts.findIndex((element) => element.id === newAccount.id);
    newAccounts[accountIndex] = newAccount;
    this.setState({ accounts: newAccounts });
  };

  render() {
    return (
      <CRUDTable
        schema={{
          headers: [
            { title: "Institution", sortingEnabled: false },
            { title: "Type", sortingEnabled: false },
            { title: "Owner", sortingEnabled: false },
            { title: "Currency", sortingEnabled: false },
            { title: "Stock Symbol", sortingEnabled: false },
            { title: "Category", sortingEnabled: false },
          ],
          rows: [
            { propertyName: "institution", inputType: "text", placeholder: "Institution" },
            { propertyName: "type", inputType: "text", placeholder: "Type" },
            { propertyName: "owner", inputType: "text", placeholder: "Owner" },
            {
              propertyName: "currency",
              inputType: "select",
              placeholder: "Currency",
              options: ["", "CAD", "USD", "EUR", "HUF"],
            },
            {
              propertyName: "stock_symbol",
              inputType: "select",
              placeholder: "Stock Symbol",
              options: ["", "AMZN", "AAPL", "BRK.B", "IVV", "TSLA", "VWAPY"].concat(
                this.state.rates
              ),
            },
            {
              propertyName: "category",
              inputType: "select",
              placeholder: "Category",
              options: ["", "Cash", "Credit", "Investment"],
            },
          ],
        }}
        data={this.state.accounts}
        actionsEnabled={true}
        editModeByDefault={false}
        onDataDeleteRequested={this.handleAccountDeleteRequest}
        onDataUpdateRequested={this.handleAccountUpdateRequest}
        onDataDeleted={this.handleAccountDelete}
        onDataUpdated={this.handleAccountUpdate}
        loading={this.state.refreshingAccountsInProgress}
      />
    );
  }
}
