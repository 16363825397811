import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { TransactionsDateFilter } from "./TransactionsDateFilter";
import { TransactionsTextFilter } from "./TransactionsTextFilter";
import { CRUDTable } from "../shared/CRUDTable";
import { PageSizeSelector } from "../shared/PageSizeSelector";
import { Pagination } from "../shared/Pagination";

export class TransactionsTable extends Component {
  constructor(props) {
    super(props);
    var fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 183);
    let toDate = new Date();
    this.state = {
      accounts: [],
      categories: [],
      transactions: [],
      selectedTransactions: [],
      pageSize: 50,
      pageCount: 0,
      selectedPage: 1,
      textFilter: "",
      dateSortAscendingDirection: false,
      fromDate: fromDate,
      toDate: toDate,
      refreshingTransactionsInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
    this.CRUDTable = React.createRef();
  }

  componentDidMount() {
    this.TransactionsTable();
  }

  TransactionsTable() {
    this.api.listAccounts((accounts, error) => {
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else {
        this.setState({ accounts: accounts });
      }
    });
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: " + error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
    this.refreshTransactions();
  }

  formatDate(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
  }

  refreshTransactions(parameters = {}) {
    const initializedParameters = {
      pageSize: parameters.pageSize ? parameters.pageSize : this.state.pageSize,
      selectedPage: parameters.selectedPage ? parameters.selectedPage : this.state.selectedPage,
      textFilter: parameters.textFilter ? parameters.textFilter : this.state.textFilter,
      dateSortAscendingDirection:
        parameters.dateSortAscendingDirection !== undefined
          ? parameters.dateSortAscendingDirection
          : this.state.dateSortAscendingDirection,
      fromDate: parameters.fromDate ? parameters.fromDate : this.state.fromDate,
      toDate: parameters.toDate ? parameters.toDate : this.state.toDate,
    };
    this.setState({
      refreshingTransactionsInProgress: true,
    });
    this.api.listTransactions(
      initializedParameters.pageSize,
      initializedParameters.selectedPage,
      initializedParameters.textFilter,
      initializedParameters.dateSortAscendingDirection,
      this.formatDate(initializedParameters.fromDate),
      this.formatDate(initializedParameters.toDate),
      (data, error) => {
        this.setState({
          refreshingTransactionsInProgress: false,
        });
        if (error) {
          alert("Failed to refresh transactions: " + error.message);
        } else {
          this.setState({
            transactions: data.transactions,
            pageCount: data.pageCount,
          });
        }
      }
    );
  }

  handleTextFilterChange = (textFilter) => {
    this.setState({ textFilter: textFilter });
    this.refreshTransactions({ textFilter: textFilter });
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ pageSize: pageSize });
    this.refreshTransactions({ pageSize: pageSize });
  };

  handleSelectedPageChange = (selectedPage) => {
    this.setState({ selectedPage: selectedPage });
    this.refreshTransactions({ selectedPage: selectedPage });
    window.scrollTo(0, 0);
  };

  handleTransactionDelete = (transaction) => {
    var newTransactions = this.state.transactions;
    newTransactions.splice(
      this.state.transactions.findIndex((element) => element.id === transaction.id),
      1
    );
    this.setState({ transactions: newTransactions });
    this.refreshTransactions();
  };

  handleTransactionUpdate = (updatedTransaction) => {
    let transactionIndex = this.state.transactions.findIndex(
      (element) => element.id === updatedTransaction.id
    );
    var newTransactions = this.state.transactions;
    newTransactions[transactionIndex] = updatedTransaction;
    this.setState({ transactions: newTransactions });
    this.refreshTransactions();
  };

  handleDateSortDirectionChange = (dateSortAscendingDirection) => {
    this.setState({ dateSortAscendingDirection: dateSortAscendingDirection });
    this.refreshTransactions({
      dateSortAscendingDirection: dateSortAscendingDirection,
    });
  };

  handleFromDateChanged = (fromDate) => {
    let parsedFromDate = new Date(fromDate);
    this.setState({ fromDate: parsedFromDate });
    this.refreshTransactions({ fromDate: parsedFromDate });
  };

  handleToDateChanged = (toDate) => {
    let parsedToDate = new Date(toDate);
    this.setState({ toDate: parsedToDate });
    this.refreshTransactions({ toDate: parsedToDate });
  };

  handleRowSelectionChange = (selectedTransactions) => {
    this.setState({ selectedTransactions: selectedTransactions });
  };

  handleTransactionDeleteRequest = (transaction, callback) => {
    if (window.confirm("Delete this transaction permanently?")) {
      this.api.deleteTransactions([transaction.id], (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete transaction: " + error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handleBatchTransactionDeleteRequest = () => {
    if (window.confirm("Delete these transactions permanently?")) {
      this.api.deleteTransactions(
        this.state.selectedTransactions.map((transaction) => transaction.id),
        (response, error) => {
          if (error) {
            alert("Failed to delete transactions: " + error.message);
          } else {
            this.resetSelectedTransactions();
            this.refreshTransactions();
          }
        }
      );
    }
  };

  handleTransactionUpdateRequest = (transaction, callback) => {
    this.api.updateTransactions([transaction.id], transaction, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update transaction: " + error.message);
      } else {
        callback(true);
      }
    });
  };

  handleBatchTransactionUpdateRequest = (newTransactionParameters) => {
    this.api.updateTransactions(
      this.state.selectedTransactions.map((transaction) => transaction.id),
      newTransactionParameters,
      (response, error) => {
        if (error) {
          alert("Failed to update transactions: " + error.message);
        } else {
          this.resetSelectedTransactions();
          this.refreshTransactions();
        }
      }
    );
  };

  resetSelectedTransactions() {
    this.setState({ selectedTransactions: [] });
    this.CRUDTable.current.resetSelectedRows();
  }

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter((category) => {
        if (category1 === undefined) return true;
        else if (category2 === undefined)
          return category.category1 === category1 && category.category2;
        else
          return (
            category.category1 === category1 &&
            category.category2 === category2 &&
            category.category3
          );
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  round(value) {
    return (Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  render() {
    return (
      <Container className="px-0">
        <Row className="my-3">
          <Col md={3}>
            <TransactionsTextFilter onTextFilterChanged={this.handleTextFilterChange} />
          </Col>
          <Col>
            <TransactionsDateFilter
              defaultFromDateValue={this.formatDate(this.state.fromDate)}
              defaultToDateValue={this.formatDate(this.state.toDate)}
              onFromDateChange={this.handleFromDateChanged}
              onToDateChange={this.handleToDateChanged}
            />
          </Col>
          <Col xs="auto">
            <PageSizeSelector
              defaultValue={this.state.pageSize}
              onPageSizeChange={this.handlePageSizeChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CRUDTable
              schema={{
                headers: [
                  { title: "Date", sortingEnabled: true },
                  { title: "Description", sortingEnabled: false },
                  { title: "Amount", sortingEnabled: false },
                  { title: "Account", sortingEnabled: false },
                  { title: "Category 1", sortingEnabled: false },
                  { title: "Category 2", sortingEnabled: false },
                  { title: "Category 3", sortingEnabled: false },
                ],
                rows: [
                  {
                    propertyName: "date",
                    inputType: "date",
                    placeholder: "yyyy-mm-dd",
                  },
                  {
                    propertyName: "description",
                    inputType: "text",
                    placeholder: "Description",
                  },
                  {
                    propertyName: "amount",
                    valueFormatter: this.round,
                    inputType: "number",
                    placeholder: "Amount",
                  },
                  {
                    propertyName: "account_name",
                    inputType: "select",
                    options: this.state.accounts.map((account) => account.name),
                    onValueChanged: (newAccountValue, editedTransaction) => {
                      var newAccount;
                      this.state.accounts.forEach((account) => {
                        if (account.name === newAccountValue) {
                          newAccount = account;
                        }
                      });
                      return {
                        ...editedTransaction,
                        account_currency: newAccount.currency,
                        account_stock_symbol: newAccount.stock_symbol,
                        account_institution: newAccount.institution,
                        account_type: newAccount.type,
                        account_owner: newAccount.owner,
                        account_name: newAccount.name,
                      };
                    },
                  },
                  {
                    propertyName: "category1",
                    inputType: "select",
                    options: this.getCategoryValues(),
                    onValueChanged: (newCategory1Value, editedTransaction) => {
                      const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                      const newCategory3 = this.getCategoryValues(
                        newCategory1Value,
                        newCategory2 ? newCategory2 : ""
                      )[0];
                      return {
                        ...editedTransaction,
                        category1: newCategory1Value,
                        category2: newCategory2 ? newCategory2 : "",
                        category3: newCategory3 ? newCategory3 : "",
                      };
                    },
                  },
                  {
                    propertyName: "category2",
                    inputType: "select",
                    options: (editedData) => this.getCategoryValues(editedData.category1),
                    onValueChanged: (newCategory2Value, editedTransaction) => {
                      const newCategory3 = this.getCategoryValues(
                        editedTransaction.category1,
                        newCategory2Value
                      )[0];
                      return {
                        ...editedTransaction,
                        category2: newCategory2Value,
                        category3: newCategory3 ? newCategory3 : "",
                      };
                    },
                  },
                  {
                    propertyName: "category3",
                    inputType: "select",
                    options: (editedData) =>
                      this.getCategoryValues(editedData.category1, editedData.category2),
                  },
                ],
              }}
              data={this.state.transactions}
              actionsEnabled={true}
              batchSelectionEnabled={true}
              editModeByDefault={false}
              onDateSortDirectionChanged={this.handleDateSortDirectionChange}
              onDataDeleteRequested={this.handleTransactionDeleteRequest}
              onDataUpdateRequested={this.handleTransactionUpdateRequest}
              onDataDeleted={this.handleTransactionDelete}
              onDataUpdated={this.handleTransactionUpdate}
              onRowSelectionChanged={this.handleRowSelectionChange}
              loading={this.state.refreshingTransactionsInProgress}
              ref={this.CRUDTable}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            <span className="fw-bold">Statistics: </span>
            Average(
            {this.round(
              this.state.transactions.reduce(
                (previousValue, transaction) => previousValue + transaction.amount,
                0
              ) / this.state.transactions.length
            )}
            ) Sum(
            {this.round(
              this.state.transactions.reduce(
                (previousValue, transaction) => previousValue + transaction.amount,
                0
              )
            )}
            )
          </Col>
        </Row>
        <Row className="my-3">
          <Col className="d-flex justify-content-center">
            <Pagination
              onSelectedPageChange={this.handleSelectedPageChange}
              pageCount={this.state.pageCount}
              className="ms-auto"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
