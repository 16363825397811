import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { AccountActivityMappingsTable } from "./AccountActivityMappingsTable";
import { AccountActivityMappingUpload } from "./AccountActivityMappingUpload";

export class AccountActivityMappings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewAccountActivityMappingModal: false,
    };
    this.accountActivityMappingsTable = React.createRef();
  }

  handleCreateNewAccountActivityMappingModalOpen = () => {
    this.setState({
      showCreateNewaccountActivityMappingModal: true,
    });
  };

  handleCreateNewAccountActivityMappingModalClose = (accountActivityMappingsRefreshed) => {
    this.setState({
      showCreateNewaccountActivityMappingModal: false,
    });
    if (accountActivityMappingsRefreshed) {
      this.accountActivityMappingsTable.current.refreshAccountActivityMappings();
    }
  };

  render() {
    return (
      <>
        <Container className='px-0'>
          <Row>
            <Col>
              <DropdownButton title='Actions' align='end' className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewAccountActivityMappingModalOpen}>
                  Create new account activity mapping
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className='my-3'>
            <Col>
              <AccountActivityMappingsTable ref={this.accountActivityMappingsTable} />
            </Col>
          </Row>
        </Container>
        <AccountActivityMappingUpload
          show={this.state.showCreateNewaccountActivityMappingModal}
          onHide={this.handleCreateNewAccountActivityMappingModalClose}
        />
      </>
    );
  }
}
