import React, { Component } from "react";
import { Outlet, Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { Logout } from "../authentication/Logout";
import Authentication from "../../authentication/Authentication";
import { LoginAndSignUp } from "../authentication/LoginAndSignUp";

export class Layout extends Component {
  constructor(props) {
    super(props);
    this.auth = Authentication.getInstance();
    this.authenticationStatusChanged = this.authenticationStatusChanged.bind(this);
    this.auth.authenticationCallback = this.authenticationStatusChanged;
    this.state = {
      isAuthenticated: this.auth.isAuthenticated,
    };
  }

  authenticationStatusChanged() {
    this.setState({
      isAuthenticated: this.auth.isAuthenticated,
    });
  }

  render() {
    if (this.state.isAuthenticated) {
      return (
        <>
          <Container>
            <Stack gap={4}>
              <Navbar sticky='top' bg='light' expand='lg'>
                <Container>
                  <Navbar.Brand href='#home'>Finance First</Navbar.Brand>
                  <Navbar.Toggle aria-controls='basic-navbar-nav' />
                  <Navbar.Collapse id='navbarScroll'>
                    <Nav className='me-auto'>
                      <Link to='/' className='nav-link'>
                        Overview
                      </Link>
                      <Link to='/transactions' className='nav-link'>
                        Transactions
                      </Link>
                      <Link to='/plannedTransactions' className='nav-link'>
                        Planned Transactions
                      </Link>
                      <Link to='/patterns' className='nav-link'>
                        Patterns
                      </Link>
                      <Link to='/accounts' className='nav-link'>
                        Accounts
                      </Link>
                      <Link to='/categories' className='nav-link'>
                        Categories
                      </Link>
                      <Link to='/rates' className='nav-link'>
                        Exchange Rates
                      </Link>
                      <Link to='/followUpManagement' className='nav-link'>
                        Follow-up Management
                      </Link>
                      <Link to='/accountActivityMappings' className='nav-link'>
                        Account Activity Mappings
                      </Link>
                      <Logout />
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              <Outlet />
            </Stack>
          </Container>
          <div className='modal-backdrop show d-lg-none'></div>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 1050,
              width: "100vw",
              height: "100vh",
            }}
            className='modal d-block d-lg-none'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <div className='modal-title h4'>Unsupported screen size</div>
                </div>
                <div className='modal-body'>
                  <p>Finance First is not compatible with this screen size just yet. Stay tuned!</p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <LoginAndSignUp />
        </div>
      );
    }
  }
}
