import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class AccountActivityUploadReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      categories: [],
      uploadInProgress: false,
      editedTransactions: [],
      selectedTransactions: [],
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.equalArrays(
        this.props.processedTransactions,
        prevProps.processedTransactions,
        (value, index) => {
          return value.id === prevProps.processedTransactions[index].id;
        }
      )
    ) {
      this.setState({ editedTransactions: this.props.processedTransactions });
    }
  }

  equalArrays(array1, array2, compareFunction) {
    return array1.length === array2.length && array1.every(compareFunction);
  }

  componentDidMount() {
    this.api.listAccounts((accounts, error) => {
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else {
        this.setState({ accounts: accounts });
      }
    });

    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: " + error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter((category) => {
        if (category1 === undefined) return true;
        else if (category2 === undefined)
          return category.category1 === category1 && category.category2;
        else
          return (
            category.category1 === category1 &&
            category.category2 === category2 &&
            category.category3
          );
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  handleEditedDataChange = (newEditedTransaction) => {
    var foundIndex;
    this.state.editedTransactions.forEach((editedTransaction, index) => {
      if (editedTransaction.id === newEditedTransaction.id) {
        foundIndex = index;
      }
    });
    const newEditedTransactions = this.state.editedTransactions;
    newEditedTransactions[foundIndex] = newEditedTransaction;

    this.setState({ editedTransactions: newEditedTransactions });
  };

  handleReviewedTransactionsUpload = () => {
    this.setState({
      uploadInProgress: true,
    });
    this.api.uploadProcessedTransactions(
      this.state.editedTransactions.filter((editedTransaction) =>
        this.state.selectedTransactions.some(
          (selectedTransaction) => selectedTransaction.id === editedTransaction.id
        )
      ),
      (response, error) => {
        this.setState({
          uploadInProgress: false,
        });
        if (error) {
          alert("Failed to upload transactions: " + error.message);
        } else {
          this.props.onHide(true);
        }
      }
    );
  };

  handleRowSelectionChange = (selectedTransactions) => {
    this.setState({ selectedTransactions: selectedTransactions });
  };

  render() {
    return (
      <Modal
        size="xl"
        show={this.props.show}
        onHide={() => {
          this.props.onHide(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Review account activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                { title: "Date", sortingEnabled: true },
                { title: "Description", sortingEnabled: false },
                { title: "Amount", sortingEnabled: false },
                { title: "Account", sortingEnabled: false },
                { title: "Category 1", sortingEnabled: false },
                { title: "Category 2", sortingEnabled: false },
                { title: "Category 3", sortingEnabled: false },
              ],
              rows: [
                { propertyName: "date", inputType: "date", placeholder: "yyyy-mm-dd" },
                { propertyName: "description", inputType: "text", placeholder: "Description" },
                {
                  propertyName: "amount",
                  valueFormatter: (value) => Math.round((value + Number.EPSILON) * 100) / 100,
                  inputType: "number",
                  placeholder: "Amount",
                },
                {
                  propertyName: "account_name",
                  inputType: "select",
                  options: this.state.accounts.map((account) => account.name),
                  onValueChanged: (newAccountValue, editedTransaction) => {
                    var newAccount;
                    this.state.accounts.forEach((account) => {
                      if (account.name === newAccountValue) {
                        newAccount = account;
                      }
                    });
                    return {
                      ...editedTransaction,
                      currency: newAccount.currency,
                      stock_symbol: newAccount.stock_symbol,
                      institution: newAccount.institution,
                      account_type: newAccount.account_type,
                      account_owner: newAccount.account_owner,
                      account_name: newAccount.name,
                    };
                  },
                },
                {
                  propertyName: "category1",
                  inputType: "select",
                  options: this.getCategoryValues(),
                  onValueChanged: (newCategory1Value, editedTransaction) => {
                    const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                    const newCategory3 = this.getCategoryValues(
                      newCategory1Value,
                      newCategory2 ? newCategory2 : ""
                    )[0];
                    return {
                      ...editedTransaction,
                      category1: newCategory1Value,
                      category2: newCategory2 ? newCategory2 : "",
                      category3: newCategory3 ? newCategory3 : "",
                    };
                  },
                },
                {
                  propertyName: "category2",
                  inputType: "select",
                  options: (editedData) => this.getCategoryValues(editedData.category1),
                  onValueChanged: (newCategory2Value, editedTransaction) => {
                    const newCategory3 = this.getCategoryValues(
                      editedTransaction.category1,
                      newCategory2Value
                    )[0];
                    return {
                      ...editedTransaction,
                      category2: newCategory2Value,
                      category3: newCategory3 ? newCategory3 : "",
                    };
                  },
                },
                {
                  propertyName: "category3",
                  inputType: "select",
                  options: (editedData) =>
                    this.getCategoryValues(editedData.category1, editedData.category2),
                },
              ],
            }}
            data={this.props.processedTransactions.map((processedTransaction, index) => {
              var newProcessedTransaction = processedTransaction;
              newProcessedTransaction.selected = !newProcessedTransaction.duplicate;
              newProcessedTransaction.id = index;
              return newProcessedTransaction;
            })}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedDataChange}
            batchSelectionEnabled={true}
            onRowSelectionChanged={this.handleRowSelectionChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            disabled={this.state.uploadInProgress}
            onClick={this.handleReviewedTransactionsUpload}
          >
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
