import React, { Component } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import Authentication from "../../authentication/Authentication";

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpEmail: "",
      signUpPassword: "",
      confirmSignUpPassword: "",
      signUpInProgress: false,
    };
    this.auth = Authentication.getInstance();
    this.signUp = this.signUp.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  signUp() {
    if (
      this.state.singUpPassword === this.state.confirmSignUpPassword ||
      this.state.signUpPassword !== 0
    ) {
      this.setState({
        signUpInProgress: true,
      });
      this.auth.signUp(this.state.signUpEmail, this.state.signUpPassword, (error) => {
        if (this._isMounted) {
          this.setState({
            signUpInProgress: false,
            signUpError: error,
          });
        }
        if (!error) {
          this.props.onEmailVerificationRequired(this.state.signUpEmail, this.state.signUpPassword);
        }
      });
    }
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleKeyUp(event) {
    if (event.keyCode === 13) {
      this.signUp();
    }
  }

  render() {
    return (
      <div>
        <Card
          border='secondary'
          style={{ width: "18rem", marginLeft: "auto", marginRight: "auto" }}>
          <Card.Header>Sign up</Card.Header>
          <Card.Body>
            <Form onKeyUp={this.handleKeyUp}>
              {this.state.signUpError && this.state.signUpError.name === "UsernameExistsException" && (
                <Alert key='warning' variant='warning'>
                  An account with the given email already exists
                </Alert>
              )}
              <Form.Group className='mb-3' controlId='signUpEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name='signUpEmail'
                  type='email'
                  placeholder='name@example.com'
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='signUpPassword'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name='signUpPassword'
                  type='password'
                  placeholder='Password'
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='confirmSignUpPassword'>
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  name='confirmSignUpPassword'
                  type='password'
                  placeholder='Confirm password'
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              {this.state.confirmSignUpPassword.length > 0 &&
                this.state.signUpPassword !== this.state.confirmSignUpPassword && (
                  <Alert key='warning' variant='warning'>
                    Passwords do not match
                  </Alert>
                )}
              {this.state.signUpPassword.length > 0 && (
                <Alert key='info' variant='info'>
                  <ul>
                    <li>8-character minimum length</li>
                    <li>Contains at least 1 number</li>
                    <li>Contains at least 1 special character</li>
                    <li>Contains at least 1 lowercase letter</li>
                    <li>Contains at least 1 uppercase letter</li>
                  </ul>
                </Alert>
              )}
              <Button
                onClick={!this.state.signUpInProgress ? this.signUp : null}
                variant='primary'
                disabled={
                  !this.state.signUpEmail ||
                  this.state.signUpPassword !== this.state.confirmSignUpPassword ||
                  this.state.signUpInProgress
                }
                type='button'>
                {this.state.signUpInProgress ? "Signing up..." : "Sign up"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
