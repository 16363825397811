import React, { Component } from "react";
import { Table } from "react-bootstrap";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class FollowUpManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followUps: [],
      refreshingFollowUpsInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.refreshRates();
  }

  refreshRates() {
    this.setState({
      refreshingFollowUpsInProgress: true,
    });
    this.api.listFollowUps((followUps, error) => {
      this.setState({
        refreshingFollowUpsInProgress: false,
      });
      if (error) {
        alert("Failed to load follow-ups: " + error.message);
      } else {
        this.setState({ followUps: followUps });
      }
    });
  }

  render() {
    const followUpRows = this.state.followUps.map((followUp, index) => {
      return (
        <tr key={index}>
          <td>{followUp.count}</td>
          <td>{followUp.description}</td>
          <td>{followUp.account_name}</td>
        </tr>
      );
    });
    return (
      <Table size="sm" responsive striped bordered hover>
        <thead>
          <tr>
            <th>Count</th>
            <th>Description</th>
            <th>Account</th>
          </tr>
        </thead>
        <tbody>{followUpRows}</tbody>
      </Table>
    );
  }
}
