import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class CategoryUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [this.createNewEmptyCategory()],
      editedCategory: this.createNewEmptyCategory(),
      uploadInProgress: false
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  createNewEmptyCategory() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      category1: "",
      category2: "",
      category3: ""
    };
  }

  handleEditedCategoryChange = (category) => {
    this.setState({editedCategory: category});
  }

  handleCategoryUpload = () => {
    this.setState({
      uploadInProgress: true
    });
    this.api.uploadCategory(this.state.editedCategory, (response, error) => {
      this.setState({
        uploadInProgress: false
      });
      if (error) {
        alert("Failed to upload category: "+error.message);
      } else {
        this.setState({
          categories: [this.createNewEmptyCategory()],
          editedCategory: this.createNewEmptyCategory()
        });
        this.props.onHide(true);
      }
    });
  };

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={() => {this.props.onHide(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Create new category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                {title: "Category 1", sortingEnabled: false},
                {title: "Category 2", sortingEnabled: false},
                {title: "Category 3", sortingEnabled: false}
              ],
              rows: [
                {propertyName: "category1", inputType: "text", placeholder: "Category 1"},
                {propertyName: "category2", inputType: "text", placeholder: "Category 2"},
                {propertyName: "category3", inputType: "text", placeholder: "Category 3"}
              ]}}
            data={this.state.categories}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedCategoryChange}
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={this.state.uploadInProgress} onClick={this.handleCategoryUpload}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
