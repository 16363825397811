import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { CurrencyCell } from "../shared/CurrencyCell";

export class SummaryAccountBalanceTable extends Component {
  formatDate(date) {
    return date.getFullYear() + " " + date.toLocaleString("en-US", { month: "long" });
  }

  render() {
    const monthHeader = this.props.dates.map((date, dateIndex) => (
      <th key={dateIndex}>{this.formatDate(date)}</th>
    ));

    const accountColumn = this.props.accounts.map((account, accountIndex) => {
      let balances = [];
      this.props.dates.forEach((date, dateIndex) => {
        let balance = 0;
        Object.entries(this.props.accountBalances).forEach(
          ([accountBalancesDate, accountBalancesForDate]) => {
            accountBalancesForDate.forEach((accountBalance) => {
              if (
                new Date(accountBalancesDate) <= date &&
                accountBalance.account_institution === account.institution &&
                accountBalance.account_type === account.type &&
                accountBalance.account_owner === account.owner &&
                accountBalance.account_currency === account.currency &&
                accountBalance.account_stock_symbol === account.stock_symbol &&
                accountBalance.balance !== undefined
              ) {
                balance = accountBalance.balance;
              }
            });
          }
        );
        balances.push(<CurrencyCell key={dateIndex} value={balance} currency={account.currency} />);
      });
      return (
        <tr className="account-balances" key={accountIndex}>
          <td className="account-balances-fixed-column" key="accountName">
            {account.name}
          </td>
          {balances}
        </tr>
      );
    });

    const totalCash = this.props.dates.map((date, dateIndex) => {
      let total = this.props.totalWealthInRequestedCurrency[dateIndex]
        ? this.props.totalWealthInRequestedCurrency[dateIndex].cash
        : 0;
      return (
        <CurrencyCell key={dateIndex} id={dateIndex} value={total} currency={this.props.currency} />
      );
    });

    const totalCredit = this.props.dates.map((date, dateIndex) => {
      let total = this.props.totalWealthInRequestedCurrency[dateIndex]
        ? this.props.totalWealthInRequestedCurrency[dateIndex].credit
        : 0;
      return (
        <CurrencyCell key={dateIndex} id={dateIndex} value={total} currency={this.props.currency} />
      );
    });

    const totalInvestments = this.props.dates.map((date, dateIndex) => {
      let total = this.props.totalWealthInRequestedCurrency[dateIndex]
        ? this.props.totalWealthInRequestedCurrency[dateIndex].investment
        : 0;
      return (
        <CurrencyCell key={dateIndex} id={dateIndex} value={total} currency={this.props.currency} />
      );
    });

    const totalWealth = this.props.dates.map((date, dateIndex) => {
      let total = this.props.totalWealthInRequestedCurrency[dateIndex]
        ? this.props.totalWealthInRequestedCurrency[dateIndex].wealth
        : 0;
      return (
        <CurrencyCell key={dateIndex} id={dateIndex} value={total} currency={this.props.currency} />
      );
    });

    return (
      <Table size="sm" responsive striped bordered hover id="accountBalances">
        <thead>
          <tr className="account-balances-fixed-row">
            <th className="account-balances-fixed-column" key="accounts">
              Accounts
            </th>
            {monthHeader}
          </tr>
        </thead>
        <tbody>
          {accountColumn}
          <tr className="totals" key="total_cash">
            <td className="account-balances-fixed-column" key="total">
              Total Cash
            </td>
            {totalCash}
          </tr>
          <tr className="totals" key="total_credit">
            <td className="account-balances-fixed-column" key="total">
              Total Credit
            </td>
            {totalCredit}
          </tr>
          <tr className="totals" key="total_investments">
            <td className="account-balances-fixed-column" key="total">
              Total Investments
            </td>
            {totalInvestments}
          </tr>
          <tr className="totals" key="total_wealth">
            <td className="account-balances-fixed-column" key="total">
              Total Wealth
            </td>
            {totalWealth}
          </tr>
        </tbody>
      </Table>
    );
  }
}
