import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";

export class TransactionsDateFilter extends Component {

  handleFromDateChanged = (event) => {
    this.props.onFromDateChange(event.target.value);
  };

  handleToDateChanged = (event) => {
    this.props.onToDateChange(event.target.value);
  };

  render() {
    return (
      <Form>
        <Row className='justify-content-center'>
          <Col md="auto">
            <Form.Group as={Row} controlId="fromDate">
              <Form.Label column style={{width: "60px", textAlign: "right", paddingRight: "10px"}}>
                From:
              </Form.Label>
              <Col>
                <Form.Control
                  className="w-auto"
                  type="date"
                  placeholder="From date"
                  defaultValue={this.props.defaultFromDateValue}
                  onChange={this.handleFromDateChanged}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="auto">
            <Form.Group as={Row} controlId="toDate">
              <Form.Label column style={{width: "60px", textAlign: "right", paddingRight: "10px"}}>
                To:
              </Form.Label>
              <Col>
                <Form.Control
                  className="w-auto"
                  type="date"
                  placeholder="To date"
                  defaultValue={this.props.defaultToDateValue}
                  onChange={this.handleToDateChanged}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  }
}
