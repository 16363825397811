import React, { Component } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import Authentication from "../../authentication/Authentication";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginInProgress: false,
    };
    this.auth = Authentication.getInstance();
    this.login = this.login.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  login() {
    this.setState({
      loginInProgress: true,
    });
    this.auth.authenticate(this.state.email, this.state.password, (error) => {
      if (this._isMounted) {
        this.setState({
          loginInProgress: false,
          loginError: error,
        });
      }
      if (error && error.name === "UserNotConfirmedException") {
        this.props.onEmailVerificationRequired(this.state.email, this.state.password);
      }
    });
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleKeyUp(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  handleEmailConfirmationModalClose = () => {};

  render() {
    return (
      <div>
        <Card
          border='secondary'
          style={{ width: "18rem", marginLeft: "auto", marginRight: "auto" }}>
          <Card.Header>Login</Card.Header>
          <Card.Body>
            <Form onKeyUp={this.handleKeyUp}>
              {this.state.loginError && this.state.loginError.name === "NotAuthorizedException" && (
                <Alert key='danger' variant='danger'>
                  Incorrect email and/or password
                </Alert>
              )}
              {this.props.loginErrorAfterEmailConfirmation && (
                <Alert key='success' variant='success'>
                  Your email has been successfully confirmed! You can login to your account now
                </Alert>
              )}
              {this.props.successfulPasswordChange && (
                <Alert key='success' variant='success'>
                  Your password has been successfully changed! You can login to your account using
                  your new password
                </Alert>
              )}
              <Form.Group className='mb-3' controlId='email'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name='email'
                  type='email'
                  placeholder='name@example.com'
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='password'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name='password'
                  type='password'
                  placeholder='Password'
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Button
                onClick={!this.state.loginInProgress ? this.login : null}
                variant='primary'
                disabled={this.state.loginInProgress}
                type='button'>
                {this.state.loginInProgress ? "Logging in..." : "Login"}
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
            <Button size='sm' variant='link' onClick={this.props.onForgotPasswordButtonPressed}>
              Forgot password?
            </Button>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}
