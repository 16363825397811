import React, { Component } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import Authentication from "../../authentication/Authentication";

export class SetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setNewPasswordCode: "",
      newPassword: "",
      confirmNewPassword: "",
      setNewPasswordInProgress: false,
    };
    this.auth = Authentication.getInstance();
  }

  handleSetNewPasswordButtonClicked = () => {
    if (this.state.newPassword === this.state.confirmNewPassword || this.state.newPassword !== 0) {
      this.setState({
        setNewPasswordInProgress: true,
      });
      this.auth.setNewPassword(
        this.state.setNewPasswordCode,
        this.state.newPassword,
        this.props.email,
        (error) => {
          this.setState({
            setNewPasswordRequestError: error,
            setNewPasswordInProgress: false,
          });
          if (!error) {
            this.props.onSetNewPasswordCompleted(true);
          }
        }
      );
    }
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Modal
        size='md'
        show={this.props.show}
        onHide={() => {
          this.props.onSetNewPasswordCompleted(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Set new password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='setNewPasswordCode'>
            <Form.Label>Please enter the confirmation code</Form.Label>
            <Form.Control
              name='setNewPasswordCode'
              type='text'
              placeholder='Confirmation code'
              onChange={this.handleInputChange}
            />
          </Form.Group>
          {this.state.setNewPasswordRequestError &&
            this.state.setNewPasswordRequestError.message === "Incorrect confirmation code" && (
              <Alert key='danger' variant='danger'>
                Incorrect confirmation code
              </Alert>
            )}
          {this.state.setNewPasswordRequestError &&
            this.state.setNewPasswordRequestError.message !== "Incorrect confirmation code" && (
              <Alert key='danger' variant='danger'>
                Something went wrong, please try again
              </Alert>
            )}
          <Form.Group className='mb-3' controlId='newPassword'>
            <Form.Label>Enter new password</Form.Label>
            <Form.Control
              name='newPassword'
              type='password'
              placeholder='New password'
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='confirmNewPassword'>
            <Form.Label>Confirm new password</Form.Label>
            <Form.Control
              name='confirmNewPassword'
              type='password'
              placeholder='Cornfirm new password'
              onChange={this.handleInputChange}
            />
          </Form.Group>
          {this.state.confirmNewPassword.length > 0 &&
            this.state.newPassword !== this.state.confirmNewPassword && (
              <Alert key='warning' variant='warning'>
                Passwords do not match
              </Alert>
            )}
          {this.state.newPassword.length > 0 && (
            <Alert key='info' variant='info'>
              <ul>
                <li>8-character minimum length</li>
                <li>Contains at least 1 number</li>
                <li>Contains at least 1 special character</li>
                <li>Contains at least 1 lowercase letter</li>
                <li>Contains at least 1 uppercase letter</li>
              </ul>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            disabled={
              !this.state.setNewPasswordCode ||
              this.state.newPassword !== this.state.confirmNewPassword ||
              this.state.newPassword.length === 0 ||
              this.state.setNewPasswordInProgress
            }
            onClick={
              !this.state.setNewPasswordInProgress ? this.handleSetNewPasswordButtonClicked : null
            }>
            {this.state.setNewPasswordInProgress ? "Setting new password..." : "Set new password"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
