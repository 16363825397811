import React, { Component } from "react";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { SummaryAccountBalanceTable } from "./SummaryAccountBalanceTable";
import { SummaryFilter } from "./SummaryFilter";
import { SummaryChart } from "./SummaryChart";
import Stack from "react-bootstrap/Stack";

export class Summary extends Component {
  constructor(props) {
    super(props);
    const nowDate = new Date();
    var fromDate = new Date();
    fromDate.setDate(nowDate.getDate() - 183);
    var toDate = new Date();
    toDate.setDate(nowDate.getDate() + 183);
    this.state = {
      accounts: [],
      accountBalances: { confirmedBalances: {}, plannedBalances: {} },
      currency: "HUF",
      fromDate: fromDate,
      nowDate: nowDate,
      toDate: toDate,
    };
    this.api = FinancialAppClient.getInstance();
  }

  // Lifecycle methods

  componentDidMount() {
    this.api.listAccounts((accounts, error) => {
      if (error) {
        alert("Failed to list accounts: " + error.message);
      } else {
        this.setState({ accounts: accounts });
      }
    });

    this.api.getAccountBalances(
      this.state.currency,
      this.formatDate(this.state.fromDate),
      this.formatDate(this.state.toDate),
      (accountBalances, error) => {
        if (error) {
          alert("Failed to get account balances: " + error.message);
        } else {
          this.setState({ accountBalances: accountBalances });
        }
      }
    );
  }

  // Event handlers

  handleFromDateChanged = (fromDate) => {
    if (this.isValidDateString(fromDate)) {
      this.api.getAccountBalances(
        this.state.currency,
        fromDate,
        this.formatDate(this.state.toDate),
        (accountBalances, error) => {
          if (error) {
            alert("Failed to get account balances: " + error.message);
          } else {
            this.setState({ accountBalances: accountBalances });
          }
        }
      );
      this.setState({ fromDate: new Date(fromDate) });
    }
  };

  handleToDateChanged = (toDate) => {
    if (this.isValidDateString(toDate)) {
      this.api.getAccountBalances(
        this.state.currency,
        this.formatDate(this.state.fromDate),
        toDate,
        (accountBalances, error) => {
          if (error) {
            alert("Failed to get account balances: " + error.message);
          } else {
            this.setState({ accountBalances: accountBalances });
          }
        }
      );
      this.setState({ toDate: new Date(toDate) });
    }
  };

  handleCurrencyChanged = (currency) => {
    this.api.getAccountBalances(
      currency,
      this.formatDate(this.state.fromDate),
      this.formatDate(this.state.toDate),
      (accountBalances, error) => {
        if (error) {
          alert("Failed to get account balances: " + error.message);
        } else {
          this.setState({ accountBalances: accountBalances });
        }
      }
    );
    this.setState({ currency: currency });
  };

  // Helper functions

  formatDate(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
  }

  calculateDates(fromDate, toDate) {
    var fromYear = fromDate.getFullYear();
    var fromMonth = fromDate.getMonth();
    var year = toDate.getFullYear();
    var month = toDate.getMonth();
    let dates = [];
    for (let i = 0; year > fromYear || (year === fromYear && month >= fromMonth); i++) {
      var targetDate = new Date(year, month + 1, 0);
      dates.unshift(targetDate);
      month--;
      if (month < 0) {
        month = 11;
        year--;
      }
    }
    return dates;
  }

  calculateTotalWealthInRequestedCurrency(dates, accountBalances) {
    return dates.map((date) => {
      var total = {
        wealth: 0,
        cash: 0,
        credit: 0,
        investment: 0,
      };
      this.state.accounts.forEach((account) => {
        let balance = 0;
        Object.entries(accountBalances).forEach(([accountBalancesDate, accountBalancesForDate]) => {
          accountBalancesForDate.forEach((accountBalance) => {
            if (
              new Date(accountBalancesDate) <= date &&
              accountBalance.account_institution === account.institution &&
              accountBalance.account_type === account.type &&
              accountBalance.account_owner === account.owner &&
              accountBalance.account_currency === account.currency &&
              accountBalance.account_stock_symbol === account.stock_symbol &&
              accountBalance.balanceInForeignCurrency[this.state.currency] !== undefined
            ) {
              balance = accountBalance.balanceInForeignCurrency[this.state.currency];
            }
          });
        });
        total.wealth += balance;
        if (account.category === "Cash") {
          total.cash += balance;
        }
        if (account.category === "Credit") {
          total.credit += balance;
        }
        if (account.category === "Investment") {
          total.investment += balance;
        }
      });
      return total;
    });
  }

  isValidDateString(dateString) {
    const date = new Date(dateString);
    return (
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date.getTime()) &&
      date.getTime() > 0
    );
  }

  render() {
    const pastDates = this.calculateDates(this.state.fromDate, this.state.nowDate);
    const pastWealthInRequestedCurrency = this.calculateTotalWealthInRequestedCurrency(
      pastDates,
      this.state.accountBalances.confirmedBalances
    );
    const futureDates = this.calculateDates(this.state.nowDate, this.state.toDate);
    const futureWealthInRequestedCurrency = this.calculateTotalWealthInRequestedCurrency(
      futureDates,
      this.state.accountBalances.plannedBalances
    );
    const overallDates = this.calculateDates(this.state.fromDate, this.state.toDate);
    const overallWealthInRequestedCurrency = this.calculateTotalWealthInRequestedCurrency(
      overallDates,
      this.state.accountBalances.plannedBalances
    );
    return (
      <Stack gap={4}>
        <SummaryFilter
          defaultFromDateValue={this.formatDate(this.state.fromDate)}
          defaultToDateValue={this.formatDate(this.state.toDate)}
          defaultCurrencyValue={this.state.currency}
          onFromDateChange={this.handleFromDateChanged}
          onToDateChange={this.handleToDateChanged}
          onCurrencyChange={this.handleCurrencyChanged}
        />
        <SummaryChart
          dates={overallDates}
          totalWealthInRequestedCurrency={overallWealthInRequestedCurrency}
          currency={this.state.currency}
        />
        <h3>Account Balances</h3>
        <SummaryAccountBalanceTable
          dates={pastDates}
          totalWealthInRequestedCurrency={pastWealthInRequestedCurrency}
          accountBalances={this.state.accountBalances.confirmedBalances}
          accounts={this.state.accounts}
          currency={this.state.currency}
        />
        <h3>Planned Account Balances</h3>
        <SummaryAccountBalanceTable
          dates={futureDates}
          totalWealthInRequestedCurrency={futureWealthInRequestedCurrency}
          accountBalances={this.state.accountBalances.plannedBalances}
          accounts={this.state.accounts}
          currency={this.state.currency}
        />
      </Stack>
    );
  }
}
