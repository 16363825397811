import React, { Component } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import Authentication from "../../authentication/Authentication";

export class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      confirmationInProgress: false,
      resendConfirmationCodeInProgress: false,
    };
    this.auth = Authentication.getInstance();
    this.emailConfirmation = this.emailConfirmation.bind(this);
  }

  emailConfirmation() {
    this.setState({
      confirmationInProgress: true,
    });
    this.auth.confirmUser(this.props.email, this.state.code, (error) => {
      if (error) {
        this.setState({
          confirmationInProgress: false,
          confirmationError: error,
        });
      } else {
        this.setState({
          confirmationInProgress: false,
        });
        this.auth.authenticate(this.props.email, this.props.password, (error) => {
          if (error) {
            this.props.onLoginError();
          }
        });
        this.props.onHide(true);
      }
    });
  }

  handleResendConfirmationCode = () => {
    this.setState({
      resendConfirmationCodeInProgress: true,
    });
    this.auth.resendConfirmationCode(this.props.email, (error) => {
      if (error) {
        this.setState({
          resendConfirmationCodeInProgress: false,
          resendConfirmationCodeError: error,
        });
      } else {
        this.setState({
          resendConfirmationCodeInProgress: false,
        });
      }
    });
  };

  handleConfirmationCodeChange = (event) => {
    this.setState({
      code: event.target.value,
    });
  };

  render() {
    return (
      <div>
        <Modal
          size='sm'
          show={this.props.show}
          onHide={() => {
            this.props.onHide(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Email confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert key='light' variant='light'>
              Please enter the confirmation code that we sent to your email address
            </Alert>
            <Form.Group className='mb-3' controlId='code'>
              <Form.Control
                name='code'
                placeholder='Confirmation code'
                onChange={this.handleConfirmationCodeChange}
              />
            </Form.Group>
            <Button
              size='sm'
              variant='link'
              disabled={this.state.resendConfirmationCodeInProgress}
              onClick={
                !this.state.resendConfirmationCodeInProgress
                  ? this.handleResendConfirmationCode
                  : null
              }>
              {this.state.resendConfirmationCodeInProgress
                ? "Sending confirmation code..."
                : "Send confirmation code again"}
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              disabled={this.state.confirmationInProgress}
              onClick={!this.state.confirmationInProgress ? this.emailConfirmation : null}>
              {this.state.confirmationInProgress ? "Confirmation..." : "Confirm"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
