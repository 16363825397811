import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Authentication from "../../authentication/Authentication";

export class Logout extends Component {
  constructor(props) {
    super(props);
    this.auth = Authentication.getInstance();
    this.logout = this.logout.bind(this);
  }

  logout() {
    this.auth.deleteToken();
  }

  render() {
    return (
      <Button onClick={this.logout} variant='primary' type='button'>
        Logout
      </Button>
    );
  }
}
