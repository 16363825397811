import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { SummaryTotalChart } from "./SummaryTotalChart";

export class SummaryChart extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <SummaryTotalChart
              dates={this.props.dates}
              totalWealthInRequestedCurrency={this.props.totalWealthInRequestedCurrency}
              currency={this.props.currency}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
