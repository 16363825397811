import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { CategoriesTable } from "./CategoriesTable";
import { CategoryUpload } from "./CategoryUpload";

export class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewCategoryModal: false,
    };
    this.categoriesTable = React.createRef();
  }

  handleCreateNewCategoryModalOpen = () => {
    this.setState({
      showCreateNewCategoryModal: true
    });
  }

  handleCreateNewCategoryModalClose = (categoriesRefreshed) => {
    this.setState({
      showCreateNewCategoryModal: false
    });
    if (categoriesRefreshed) {
      this.categoriesTable.current.refreshCategories();
    }
  }

  render() {
    return (
      <>
        <Container className="px-0">
          <Row>
            <Col>
              <DropdownButton title="Actions" align="end" className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewCategoryModalOpen}>Create new category</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <CategoriesTable ref={this.categoriesTable}/>
            </Col>
          </Row>
        </Container>
        <CategoryUpload
          show={this.state.showCreateNewCategoryModal}
          onHide={this.handleCreateNewCategoryModalClose}/>
      </>
    );
  }
}
