import config from "../config";
import Authentication from "../authentication/Authentication";
import retry from "retry";

let instance = null;

export default class FinancialAppClient {
  constructor() {
    this.auth = Authentication.getInstance();
  }

  // --------------------------------- //
  //    INTERNAL DEVELOPMENT TOOLS     //
  // --------------------------------- //

  cleanBalanceCache() {
    this.get("/development_tools/clean_balance_cache", () => {});
  }

  // --------------------------------- //
  //          TRANSACTIONS             //
  // --------------------------------- //

  listTransactions(
    pageSize,
    selectedPage,
    textFilter,
    dateSortAscendingDirection,
    fromDate,
    toDate,
    completion
  ) {
    this.get(
      "/transactions/?pageSize=" +
        pageSize +
        "&page=" +
        selectedPage +
        "&textFilter=" +
        JSON.stringify(textFilter) +
        "&dateSortAscendingDirection=" +
        JSON.stringify(dateSortAscendingDirection) +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
      (data, error) => {
        completion(
          {
            ...data,
            transactions: data.transactions.map((transaction) => {
              transaction.account_name = this.getTransactionAccountName(transaction);
              return transaction;
            }),
          },
          error
        );
      }
    );
  }

  updateTransactions(ids, transaction, completion) {
    this.post(
      "/transactions/update",
      {
        ids: JSON.stringify(ids),
        transaction: JSON.stringify(transaction),
      },
      completion
    );
  }

  uploadTransaction(transaction, completion) {
    this.post(
      "/transactions",
      {
        transaction: JSON.stringify(transaction),
      },
      completion
    );
  }

  deleteTransactions(ids, completion) {
    this.post(
      "/transactions/delete",
      {
        ids: JSON.stringify(ids),
      },
      completion
    );
  }

  uploadTransactions(transactions, completion) {
    this.post(
      "/transactions/uploadTransactions",
      {
        transactions: transactions,
      },
      completion
    );
  }

  uploadProcessedTransactions(transactions, completion) {
    this.post(
      "/transactions/uploadProcessedTransactions",
      {
        transactions: JSON.stringify(transactions),
      },
      completion
    );
  }

  processAccountActivity(account, accountActivityMapping, transactions, completion) {
    this.post(
      "/transactions/processAccountActivity",
      {
        accountActivityMapping: JSON.stringify(accountActivityMapping),
        account: JSON.stringify(account),
        transactions: transactions,
      },
      completion
    );
  }

  listLatestTransactionDates(completion) {
    this.get("/transactions/latestTransactionDates", completion);
  }

  // --------------------------------- //
  //      PLANNED TRANSACTIONS         //
  // --------------------------------- //

  listPlannedTransactions(
    pageSize,
    selectedPage,
    textFilter,
    dateSortAscendingDirection,
    fromDate,
    toDate,
    completion
  ) {
    this.get(
      "/plannedTransactions/?pageSize=" +
        pageSize +
        "&page=" +
        selectedPage +
        "&textFilter=" +
        JSON.stringify(textFilter) +
        "&dateSortAscendingDirection=" +
        JSON.stringify(dateSortAscendingDirection) +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
      completion
    );
  }

  updatePlannedTransaction(plannedTransaction, completion) {
    this.post(
      "/plannedTransactions/" + plannedTransaction.id,
      {
        plannedTransaction: JSON.stringify(plannedTransaction),
      },
      completion
    );
  }

  uploadPlannedTransaction(plannedTransaction, completion) {
    this.post(
      "/plannedTransactions",
      {
        plannedTransaction: JSON.stringify(plannedTransaction),
      },
      completion
    );
  }

  uploadRecurringPlannedTransaction(plannedTransaction, recurrence, completion) {
    this.post(
      "/plannedTransactions/uploadRecurringPlannedTransaction",
      {
        recurrence: JSON.stringify(recurrence),
        plannedTransaction: JSON.stringify(plannedTransaction),
      },
      completion
    );
  }

  deletePlannedTransaction(id, completion) {
    this.delete("/plannedTransactions/" + id, completion);
  }

  // --------------------------------- //
  //            BALANCES               //
  // --------------------------------- //

  getAccountBalances(currency, fromDate, toDate, completion) {
    this.get(
      "/balances/account?currency=" + currency + "&fromDate=" + fromDate + "&toDate=" + toDate,
      completion
    );
  }

  // --------------------------------- //
  //           CATEGORIES              //
  // --------------------------------- //

  listCategories(completion) {
    this.get("/categories", completion);
  }

  uploadCategory(category, completion) {
    this.post(
      "/categories",
      {
        category: JSON.stringify(category),
      },
      completion
    );
  }

  deleteCategory(id, completion) {
    this.delete("/categories/" + id, completion);
  }

  updateCategory(category, completion) {
    this.post(
      "/categories/" + category.id,
      {
        category: JSON.stringify(category),
      },
      completion
    );
  }

  // --------------------------------- //
  //            ACCOUNTS               //
  // --------------------------------- //

  listAccounts(completion) {
    this.get("/accounts", completion);
  }

  uploadAccount(account, completion) {
    this.post(
      "/accounts",
      {
        account: JSON.stringify(account),
      },
      completion
    );
  }

  deleteAccount(id, completion) {
    this.delete("/accounts/" + id, completion);
  }

  updateAccount(account, completion) {
    this.post(
      "/accounts/" + account.id,
      {
        account: JSON.stringify(account),
      },
      completion
    );
  }

  // --------------------------------- //
  //            PATTERNS               //
  // --------------------------------- //

  listPatterns(completion) {
    this.get("/patterns", completion);
  }

  uploadPattern(pattern, completion) {
    this.post(
      "/patterns",
      {
        pattern: JSON.stringify(pattern),
      },
      completion
    );
  }

  deletePattern(id, completion) {
    this.delete("/patterns/" + id, completion);
  }

  updatePattern(pattern, completion) {
    this.post(
      "/patterns/" + pattern.id,
      {
        pattern: JSON.stringify(pattern),
      },
      completion
    );
  }

  // --------------------------------- //
  //              RATES                //
  // --------------------------------- //

  listCustomRates(completion) {
    this.get("/rates/custom", completion);
  }

  uploadCustomRate(rate, completion) {
    this.post(
      "/rates/custom",
      {
        rate: JSON.stringify(rate),
      },
      completion
    );
  }

  deleteCustomRate(id, completion) {
    this.delete("/rates/custom/" + id, completion);
  }

  updateCustomRate(rate, completion) {
    this.post(
      "/rates/custom/" + rate.id,
      {
        rate: JSON.stringify(rate),
      },
      completion
    );
  }

  // --------------------------------- //
  //           FOLLOW-UPS              //
  // --------------------------------- //

  listFollowUps(completion) {
    this.get("/followUps", (followUps, error) => {
      completion(
        followUps.map((followUp) => {
          followUp.account_name = this.getTransactionAccountName(followUp);
          return followUp;
        }),
        error
      );
    });
  }

  // --------------------------------- //
  //     ACCOUNT ACTIVITY MAPPINGS     //
  // --------------------------------- //

  listAccountActivityMappings(completion) {
    this.get("/accountActivityMappings", completion);
  }

  uploadAccountActivityMapping(accountActivityMapping, completion) {
    this.post(
      "/accountActivityMappings",
      {
        accountActivityMapping: JSON.stringify(accountActivityMapping),
      },
      completion
    );
  }

  deleteAccountActivityMapping(id, completion) {
    this.delete("/accountActivityMappings/" + id, completion);
  }

  updateAccountActivityMapping(accountActivityMapping, completion) {
    this.post(
      "/accountActivityMappings/" + accountActivityMapping.id,
      {
        accountActivityMapping: JSON.stringify(accountActivityMapping),
      },
      completion
    );
  }

  // Helper functions

  getAccountName(account) {
    return (
      account.institution +
      " " +
      (account.type ? account.type + " " : "") +
      (account.currency ? account.currency : account.stock_symbol) +
      " - " +
      account.owner
    );
  }

  getTransactionAccountName(transaction) {
    return (
      transaction.account_institution +
      " " +
      (transaction.account_type ? transaction.account_type + " " : "") +
      (transaction.account_currency
        ? transaction.account_currency
        : transaction.account_stock_symbol) +
      " - " +
      transaction.account_owner
    );
  }

  get(url, completion) {
    this.fetch(url, null, completion);
  }

  post(url, data, completion) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    this.fetch(
      url,
      {
        method: "POST",
        body: formData,
      },
      completion
    );
  }

  delete(url, completion) {
    this.fetch(url, { method: "DELETE" }, completion);
  }

  fetch(url, options, completion) {
    var operation = retry.operation({
      retries: 5,
      factor: 3,
      minTimeout: 1 * 1000,
      maxTimeout: 60 * 1000,
      randomize: true,
    });
    var retriableError, success;
    operation.attempt(() => {
      fetch(this.createURL(url), options)
        .then((response) => {
          retriableError = response.status >= 500;
          success = response.ok;
          return response.json();
        })
        .then((json) => {
          if (operation.retry(retriableError ? new Error("Failed") : undefined)) {
            return;
          }
          success ? completion(json, null) : completion(null, json);
        })
        .catch((error) => {
          if (operation.retry(error)) {
            return;
          }
          completion(null, error);
        });
    });
  }

  createURL(url) {
    var urlObject = new URL(config.backend_domain + url);
    urlObject.searchParams.append("token", this.auth.token);
    return urlObject.toString();
  }

  // Singleton

  static getInstance() {
    if (!instance) instance = new FinancialAppClient();
    return instance;
  }
}
