import React, { Component } from "react";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { CRUDTable } from "../shared/CRUDTable";

export class AccountActivityMappingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountActivityMappings: [],
      refreshingAccountActivityMappingsInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.refreshAccountActivityMappings();
  }

  refreshAccountActivityMappings() {
    this.setState({
      refreshingAccountActivityMappingsInProgress: true,
    });
    this.api.listAccountActivityMappings((accountActivityMappings, error) => {
      this.setState({
        refreshingAccountActivityMappingsInProgress: false,
      });
      if (error) {
        alert("Failed to load account activity mappings: " + error.message);
      } else {
        this.setState({ accountActivityMappings: accountActivityMappings });
      }
    });
  }

  handleAccountActivityMappingDeleteRequest = (accountActivityMapping, callback) => {
    if (window.confirm("Delete this account activity mapping permanently?")) {
      this.api.deleteAccountActivityMapping(accountActivityMapping.id, (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete account activity mapping: " + error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handleAccountActivityMappingDelete = (accountActivityMapping) => {
    var newAccountActivityMappings = this.state.accountActivityMappings;
    newAccountActivityMappings.splice(
      this.state.accountActivityMappings.findIndex(
        (element) => element.id === accountActivityMapping.id
      ),
      1
    );
    this.setState({ newaccountActivityMappings: newAccountActivityMappings });
  };

  handleAccountActivityMappingUpdateRequest = (accountActivityMapping, callback) => {
    this.api.updateAccountActivityMapping(accountActivityMapping, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update account activity mapping: " + error.message);
      } else {
        callback(true);
      }
    });
  };

  handleAccountActivityMappingUpdate = (newAccountActivityMapping) => {
    var newAccountActivityMappings = this.state.accountActivityMappings;
    let accountActivityMappingIndex = this.state.accountActivityMappings.findIndex(
      (element) => element.id === newAccountActivityMapping.id
    );
    newAccountActivityMappings[accountActivityMappingIndex] = newAccountActivityMapping;
    this.setState({ accountActivityMappings: newAccountActivityMappings });
  };

  render() {
    return (
      <CRUDTable
        schema={{
          headers: [
            { title: "Account Name", sortingEnabled: false },
            { title: "No Header", sortingEnabled: false },
            { title: "Date Column number", sortingEnabled: false },
            { title: "Date Format", sortingEnabled: false },
            { title: "Description Column number", sortingEnabled: false },
            { title: "Amount Column number", sortingEnabled: false },
            { title: "Negative Amount Column number", sortingEnabled: false },
            { title: "Delimiter", sortingEnabled: false },
          ],
          rows: [
            {
              propertyName: "account_name",
              inputType: "text",
              placeholder: "Name",
            },
            {
              propertyName: "no_header",
              inputType: "select",
              placeholder: "No Header",
              options: ["", "true", "false"],
            },
            {
              propertyName: "date_column_number",
              inputType: "text",
              placeholder: "Date Column number",
            },
            {
              propertyName: "date_format",
              inputType: "text",
              placeholder: "Date Format",
            },
            {
              propertyName: "description_column_number",
              inputType: "text",
              placeholder: "Description Column number",
            },
            {
              propertyName: "amount_column_number",
              inputType: "text",
              placeholder: "Amount Column number",
            },
            {
              propertyName: "negative_amount_column_number",
              inputType: "text",
              placeholder: "Negative Amount Column number",
            },
            {
              propertyName: "delimiter",
              inputType: "text",
              placeholder: "Delimiter",
            },
          ],
        }}
        data={this.state.accountActivityMappings}
        actionsEnabled={true}
        editModeByDefault={false}
        onDataDeleteRequested={this.handleAccountActivityMappingDeleteRequest}
        onDataUpdateRequested={this.handleAccountActivityMappingUpdateRequest}
        onDataDeleted={this.handleAccountActivityMappingDelete}
        onDataUpdated={this.handleAccountActivityMappingUpdate}
      />
    );
  }
}
