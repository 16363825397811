import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { PlannedTransactionsTable } from "./PlannedTransactionsTable";
import { PlannedTransactionUpload } from "./PlannedTransactionUpload";
import { RecurringPlannedTransactionUpload } from "./RecurringPlannedTransactionUpload";

export class PlannedTransactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showCreateNewPlannedTransactionModal: false,
      showCreateNewRecurringPlannedTransactionModal: false
    };
    this.plannedTransactionsTable = React.createRef();
  }

  handleCreateNewPlannedTransactionModalOpen = () => {
    this.setState({
      showCreateNewPlannedTransactionModal: true
    });
  }

  handleCreateNewPlannedTransactionModalClose = (plannedTransactionsRefreshed) => {
    this.setState({
      showCreateNewPlannedTransactionModal: false
    });
    if (plannedTransactionsRefreshed) {
      this.plannedTransactionsTable.current.refreshPlannedTransactions();
    }
  }

  handleCreateNewRecurringPlannedTransactionModalOpen = () => {
    this.setState({
      showCreateNewRecurringPlannedTransactionModal: true
    });
  }

  handleCreateNewRecurringPlannedTransactionModalClose = (plannedTransactionsRefreshed) => {
    this.setState({
      showCreateNewRecurringPlannedTransactionModal: false
    });
    if (plannedTransactionsRefreshed) {
      this.plannedTransactionsTable.current.refreshPlannedTransactions();
    }
  }

  render() {
    return (
      <>
        <Container className="px-0">
          <Row>
            <Col>
              <DropdownButton title="Actions" align="end" className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewPlannedTransactionModalOpen}>Create new planned transaction</Dropdown.Item>
                <Dropdown.Item onClick={this.handleCreateNewRecurringPlannedTransactionModalOpen}>Create new recurring planned transaction</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <PlannedTransactionsTable ref={this.plannedTransactionsTable}/>
            </Col>
          </Row>
        </Container>
        <PlannedTransactionUpload
          show={this.state.showCreateNewPlannedTransactionModal}
          onHide={this.handleCreateNewPlannedTransactionModalClose}/>
        <RecurringPlannedTransactionUpload
          show={this.state.showCreateNewRecurringPlannedTransactionModal}
          onHide={this.handleCreateNewRecurringPlannedTransactionModalClose}/>
      </>
    );
  }
}
