const environment = process.env.REACT_APP_ENV;

var config = {};
if (environment !== undefined) {
  config = require('./config.'+environment);
  config.environment = environment;
} else {
  throw new Error("No REACT_APP_ENV variable defined. Please define before running again.");
}

export default config;