import React, { Component } from "react";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { CRUDTable } from "../shared/CRUDTable";

export class CategoriesTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      refreshingCategoriesInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.CategoriesTable();
  }

  CategoriesTable() {
    this.refreshCategories();
  }

  refreshCategories() {
    this.setState({
      refreshingCategoriesInProgress: true,
    });
    this.api.listCategories((categories, error) => {
      this.setState({
        refreshingCategoriesInProgress: false,
      });
      if (error) {
        alert("Failed to load categories: "+error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  handleCategoryDeleteRequest = (category, callback) => {
    if (window.confirm("Delete this category permanently?")) {
      this.api.deleteCategory(category.id, (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete category: "+error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handleCategoryUpdateRequest = (category, callback) => {
    this.api.updateCategory(category, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update category: "+error.message);
      } else {
        callback(true);
      }
    });
  };

  handleCategoryDelete = (category) => {
    var newCategories = this.state.categories;
    newCategories.splice(
      this.state.categories.findIndex((element) => element.id === category.id),
      1
    );
    this.setState({ categories: newCategories });
  };

  handleCategoryUpdate = (newCategory) => {
    var newCategories = this.state.categories;
    let categoryIndex = this.state.categories.findIndex((element) => element.id === newCategory.id);
    newCategories[categoryIndex] = newCategory;
    this.setState({ categories: newCategories });
  };

  render() {
    return (
      <CRUDTable
        schema={{
          headers: [
            {title: "Category 1", sortingEnabled: false},
            {title: "Category 2", sortingEnabled: false},
            {title: "Category 3", sortingEnabled: false}
          ],
          rows: [
            {propertyName: "category1", inputType: "text", placeholder: "Category 1"},
            {propertyName: "category2", inputType: "text", placeholder: "Category 2"},
            {propertyName: "category3", inputType: "text", placeholder: "Category 3"}
          ]}}
        data={this.state.categories}
        actionsEnabled={true}
        editModeByDefault={false}
        onDataDeleteRequested={this.handleCategoryDeleteRequest}
        onDataUpdateRequested={this.handleCategoryUpdateRequest}
        onDataDeleted={this.handleCategoryDelete}
        onDataUpdated={this.handleCategoryUpdate}
        loading={this.state.refreshingCategoriesInProgress}
      />
    );
  }
}
