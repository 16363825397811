import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";

export class SummaryTotalChart extends Component {
  constructor(props) {
    super(props);
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  }

  formatDate(date) {
    return date.getFullYear() + " " + date.toLocaleString("en-US", { month: "long" });
  }

  prepareData() {
    const labels = this.props.dates.map((date) => {
      return this.formatDate(date);
    });
    const totalWealth = this.props.dates.map((date, dateIndex) => {
      let total = this.props.totalWealthInRequestedCurrency[dateIndex]
        ? this.props.totalWealthInRequestedCurrency[dateIndex].wealth
        : 0;
      return total;
    });
    const options = {
      responsive: true,
      aspectRatio: 4,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          label: "Total Wealth (" + this.props.currency + ")",
          data: totalWealth,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };

    return [options, data];
  }

  render() {
    let [options, data] = this.prepareData();
    return (
      <Card>
        <Card.Body>
          <Card.Title>Total wealth ({this.props.currency})</Card.Title>
          <Line options={options} data={data} />
        </Card.Body>
      </Card>
    );
  }
}
