import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { PatternsTable } from "./PatternsTable";
import { PatternUpload } from "./PatternUpload";

export class Patterns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewPatternModal: false,
    };
    this.patternsTable = React.createRef();
  }

  handleCreateNewPatternModalOpen = () => {
    this.setState({
      showCreateNewPatternModal: true
    });
  }

  handleCreateNewPatternModalClose = (patternsRefreshed) => {
    this.setState({
      showCreateNewPatternModal: false
    });
    if (patternsRefreshed) {
      this.patternsTable.current.refreshPatterns();
    }
  }

  render() {
    return (
      <>
        <Container className="px-0">
          <Row>
            <Col>
              <DropdownButton title="Actions" align="end" className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewPatternModalOpen}>Create new pattern</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <PatternsTable ref={this.patternsTable}/>
            </Col>
          </Row>
        </Container>
        <PatternUpload
          show={this.state.showCreateNewPatternModal}
          onHide={this.handleCreateNewPatternModalClose}/>
      </>
    );
  }
}
