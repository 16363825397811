import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class AccountActivityMappingUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountActivityMappings: [this.createNewEmptyAccountActivityMapping()],
      editedAccountActivityMapping: this.createNewEmptyAccountActivityMapping(),
      uploadInProgress: false,
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  createNewEmptyAccountActivityMapping() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      account_name: "",
      no_header: "",
      date_column_number: "",
      date_format: "",
      description_column_number: "",
      amount_column_number: "",
      negative_amount_column_number: "",
      delimiter: "",
    };
  }

  handleEditedAccountActivityMappingChange = (accountActivityMapping) => {
    this.setState({ editedAccountActivityMapping: accountActivityMapping });
  };

  handleAccountActivityMappingUpload = () => {
    this.setState({
      uploadInProgress: true,
    });
    this.api.uploadAccountActivityMapping(
      this.state.editedAccountActivityMapping,
      (response, error) => {
        this.setState({
          uploadInProgress: false,
        });
        if (error) {
          alert("Failed to upload account activity mapping: " + error.message);
        } else {
          this.setState({
            accountActivityMappings: [this.createNewEmptyAccountActivityMapping()],
            editedAccountActivityMapping: this.createNewEmptyAccountActivityMapping(),
          });
          this.props.onHide(true);
        }
      }
    );
  };

  render() {
    return (
      <Modal
        size='xl'
        show={this.props.show}
        onHide={() => {
          this.props.onHide(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Create new account activity mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                { title: "Account Name", sortingEnabled: false },
                { title: "No Header", sortingEnabled: false },
                { title: "Date Column number", sortingEnabled: false },
                { title: "Date Format", sortingEnabled: false },
                { title: "Description Column number", sortingEnabled: false },
                { title: "Amount Column number", sortingEnabled: false },
                { title: "Negative Amount Column number", sortingEnabled: false },
                { title: "Delimiter", sortingEnabled: false },
              ],
              rows: [
                {
                  propertyName: "account_name",
                  inputType: "text",
                  placeholder: "Name",
                },
                {
                  propertyName: "no_header",
                  inputType: "select",
                  placeholder: "No Header",
                  options: ["", "true", "false"],
                },
                {
                  propertyName: "date_column_number",
                  inputType: "text",
                  placeholder: "Date Column number",
                },
                {
                  propertyName: "date_format",
                  inputType: "text",
                  placeholder: "Date Format",
                },
                {
                  propertyName: "description_column_number",
                  inputType: "text",
                  placeholder: "Description Column number",
                },
                {
                  propertyName: "amount_column_number",
                  inputType: "text",
                  placeholder: "Amount Column number",
                },
                {
                  propertyName: "negative_amount_column_number",
                  inputType: "text",
                  placeholder: "Negative Amount Column number",
                },
                {
                  propertyName: "delimiter",
                  inputType: "text",
                  placeholder: "Delimiter",
                },
              ],
            }}
            data={this.state.accountActivityMappings}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedAccountActivityMappingChange}
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            disabled={this.state.uploadInProgress}
            onClick={this.handleAccountActivityMappingUpload}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
