import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

export class SummaryCurrencyFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: 'HUF'
    };
  }

  handleCurrencyChanged = (event) => {
    this.props.onCurrencyChange(event.target.value);
  };

  render() {
    return (
      <Form>
        <Form.Group as={Row} controlId="fromDate" className="justify-content-end">
          <Form.Label column xxl="auto">
            Currency:
          </Form.Label>
          <Col xxl="auto">
            <Form.Select onChange={this.handleCurrencyChanged} defaultValue={this.props.defaultValue}>
              <option value="HUF">HUF</option>
              <option value="USD">USD</option>
              <option value="CAD">CAD</option>
              <option value="EUR">EUR</option>
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>
    );
  }
}
