import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

export class CRUDTableSorting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ascendingDirection: false,
    };
  }

  handleSortDirectionChanged = () => {
    this.setState({ ascendingDirection: !this.state.ascendingDirection });
    this.props.onSortDirectionChanged(!this.state.ascendingDirection);
  };

  render() {
    return <FontAwesomeIcon icon={this.state.ascendingDirection ? faChevronDown : faChevronUp} onClick={this.handleSortDirectionChanged} />;
  }
}
