import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Control from "react-bootstrap/FormControl";
import { CRUDTableRowActions } from "./CRUDTableRowActions";

export class CRUDTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedData: { ...props.data },
      editMode: props.editModeByDefault,
      updateInProgress: false,
      deleteInProgress: false,
    };
  }

  componentDidMount() {
    this.CRUDTableRow();
  }

  CRUDTableRow() {
    this.props.onInitializeEditedDataRequested(this.state.editedData, (newEditedData) => {
      this.setState({
        editedData: newEditedData,
      });
      if (this.props.onEditedDataChanged) {
        this.props.onEditedDataChanged(newEditedData);
      }
    });
  }

  handleDataChange = (newValue, property, onValueChanged) => {
    var newEditedData = {
      ...this.state.editedData,
    };
    if (onValueChanged) {
      newEditedData = onValueChanged(newValue, newEditedData);
    } else {
      newEditedData[property] = newValue;
    }
    this.setState({
      editedData: newEditedData,
    });
    if (this.props.onEditedDataChanged) {
      this.props.onEditedDataChanged(newEditedData);
    }
  };

  handleDataDeleteRequest = () => {
    this.setState({ deleteInProgress: true });
    this.props.onDataDeleteRequested(this.props.data, (success) => {
      this.setState({ deleteInProgress: false });
      if (success) this.props.onDataDeleted(this.props.data);
    });
  };

  handleDataUpdateRequest = () => {
    this.setState({ updateInProgress: true });
    this.props.onDataUpdateRequested(this.state.editedData, (success) => {
      this.setState({ updateInProgress: false });
      if (success) {
        this.props.onDataUpdated(this.state.editedData);
        this.setState({ editMode: false });
      }
    });
  };

  handleOpenDataEditor = () => {
    this.setState({ editMode: true, editedData: { ...this.props.data } });
    this.props.onDataEditorModeChanged(true);
  };

  handleCloseDataEditor = () => {
    this.setState({ editMode: false });
    this.props.onDataEditorModeChanged(false);
  };

  handleRowSelectionChange = (event) => {
    this.props.onRowSelectionChanged(event.target.checked, this.props.data, this.props.id);
  };

  render() {
    const cellElements = this.props.schema.map((row) => {
      var inputElement;
      switch (row.inputType) {
        case "date":
        case "text":
          inputElement = (
            <Control
              type={row.inputType}
              placeholder={row.placeholder}
              value={this.state.editedData[row.propertyName]}
              disabled={this.state.updateInProgress}
              onChange={(event) => {
                this.handleDataChange(event.target.value, row.propertyName, row.onValueChanged);
              }}
            />
          );
          break;
        case "number":
          inputElement = (
            <Control
              type="number"
              pattern="[+-]?\d+(?:[.]\d+)?"
              placeholder={row.placeholder}
              value={this.state.editedData[row.propertyName]}
              disabled={this.state.updateInProgress}
              onChange={(event) => {
                this.handleDataChange(
                  parseFloat(event.target.value),
                  row.propertyName,
                  row.onValueChanged
                );
              }}
            />
          );
          break;
        case "select":
          var optionElements = (
            <option key="loading" value="Loading...">
              Loading...
            </option>
          );
          if (row.options !== undefined) {
            const options = Array.isArray(row.options)
              ? row.options
              : row.options(this.state.editedData);
            optionElements = options.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            });
          }
          inputElement = (
            <Control
              as="select"
              disabled={this.state.updateInProgress || row.options.length === 0}
              onChange={(event) => {
                this.handleDataChange(event.target.value, row.propertyName, row.onValueChanged);
              }}
              value={this.state.editedData[row.propertyName]}
            >
              {optionElements}
            </Control>
          );
          break;
        default:
          inputElement = <></>;
          break;
      }
      return (
        <td
          key={row.propertyName}
          style={{ textAlign: row.inputType === "number" ? "right" : "left" }}
        >
          {this.state.editMode
            ? inputElement
            : row.valueFormatter
            ? row.valueFormatter(this.props.data[row.propertyName])
            : this.props.data[row.propertyName]}
        </td>
      );
    });

    return (
      <tr key={this.props.id}>
        {this.props.batchSelectionEnabled ? (
          <td>
            <Form.Check
              type="checkbox"
              onChange={this.handleRowSelectionChange}
              checked={this.props.selected}
            />
          </td>
        ) : (
          <></>
        )}
        {cellElements}
        {this.props.actionsEnabled ? (
          <td>
            <CRUDTableRowActions
              onOpenDataEditor={this.handleOpenDataEditor}
              onCloseDataEditor={this.handleCloseDataEditor}
              onDataDeleteRequested={this.handleDataDeleteRequest}
              onDataUpdateRequested={this.handleDataUpdateRequest}
              updateInProgress={this.state.updateInProgress}
              deleteInProgress={this.state.deleteInProgress}
              editMode={this.state.editMode}
            />
          </td>
        ) : (
          <></>
        )}
      </tr>
    );
  }
}
