import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { Table, Container, Spinner } from "react-bootstrap";
import { CRUDTableSorting } from "./CRUDTableSorting";
import { CRUDTableRow } from "./CRUDTableRow";

export class CRUDTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: props.metadata,
      numberOfRowsInEditMode: props.editModeByDefault ? 1 : 0,
      initializeEditedDataWithMetadataRequests: [],
      selectedData: [],
      rowSelected: [],
      selectAll: false,
    };
  }

  componentDidMount() {
    this.changeAllSelectedRowsBasedOnData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.equalArrays(
        this.props.data.map((data) => data.id),
        prevProps.data.map((data) => data.id)
      )
    ) {
      this.changeAllSelectedRowsBasedOnData(this.props.data);
    }
  }

  equalArrays(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every(function (value, index) {
        return value === array2[index];
      })
    );
  }

  readyToInitializeEditedDataWithMetadata(metadata) {
    this.setState({ metadata: metadata });
    this.state.initializeEditedDataWithMetadataRequests.forEach((request) => {
      request.request(
        this.props.onInitializeEditedDataWithMetadataRequested(request.editedData, metadata)
      );
    });
  }

  handleDataEditorModeChange = (editMode) => {
    this.setState((prevState) => ({
      numberOfRowsInEditMode: editMode
        ? prevState.numberOfRowsInEditMode + 1
        : prevState.numberOfRowsInEditMode - 1,
    }));
  };

  handleInitializeEditedDataRequest = (editedData, request) => {
    if (this.state.metadata) {
      request(
        this.props.onInitializeEditedDataWithMetadataRequested(editedData, this.state.metadata)
      );
    } else {
      var newInitializeEditedDataWithMetadataRequests =
        this.state.initializeEditedDataWithMetadataRequests;
      newInitializeEditedDataWithMetadataRequests.push({
        editedData: editedData,
        request: request,
      });
      this.setState({
        initializeEditedDataWithMetadataRequests: newInitializeEditedDataWithMetadataRequests,
      });
    }
  };

  handleRowSelectionChange = (selected, data, index) => {
    var newSelectedData = this.state.selectedData;
    if (selected) {
      newSelectedData.push(data);
    } else {
      newSelectedData.splice(
        this.state.selectedData.findIndex((element) => element.id === data.id),
        1
      );
    }
    var newRowSelected = this.state.rowSelected;
    newRowSelected[index] = selected;
    this.setState({
      selectedData: newSelectedData,
      rowSelected: newRowSelected,
    });
    this.props.onRowSelectionChanged(newSelectedData);
  };

  handleSelectAllRowsChange = (event) => {
    this.changeAllSelectedRowsTo(event.target.checked);
  };

  resetSelectedRows() {
    this.changeAllSelectedRowsTo(false);
  }

  changeAllSelectedRowsTo(selected) {
    const newSelectedData = this.props.data.filter((data) => selected);
    const newRowSelected = this.props.data.map((data) => selected);
    this.setState({
      selectedData: newSelectedData,
      rowSelected: newRowSelected,
      selectAll: selected,
    });
    this.props.onRowSelectionChanged(newSelectedData);
  }

  changeAllSelectedRowsBasedOnData(data) {
    const newSelectedData = data.filter((data) => data.selected);
    const newRowSelected = data.map((data) => data.selected);
    this.setState({
      selectedData: newSelectedData,
      rowSelected: newRowSelected,
      selectAll: newSelectedData.length === data.length,
    });
    if (typeof this.props.onRowSelectionChanged === "function") {
      this.props.onRowSelectionChanged(newSelectedData);
    }
  }

  render() {
    const tableHeaders = this.props.schema.headers.map((header) => (
      <th key={header.title}>
        {header.title}
        {this.state.numberOfRowsInEditMode === 0 && header.sortingEnabled && (
          <>
            {" "}
            <CRUDTableSorting onSortDirectionChanged={this.props.onDateSortDirectionChanged} />
          </>
        )}
      </th>
    ));

    const dataRows = this.props.data.map((data, index) => {
      return (
        <CRUDTableRow
          key={index}
          id={index}
          data={data}
          selected={this.state.rowSelected[index] ? true : false}
          schema={this.props.schema.rows}
          actionsEnabled={this.props.actionsEnabled}
          batchSelectionEnabled={this.props.batchSelectionEnabled}
          editModeByDefault={this.props.editModeByDefault}
          onDataDeleteRequested={this.props.onDataDeleteRequested}
          onDataUpdateRequested={this.props.onDataUpdateRequested}
          onDataDeleted={this.props.onDataDeleted}
          onDataUpdated={this.props.onDataUpdated}
          onDataEditorModeChanged={this.handleDataEditorModeChange}
          onInitializeEditedDataRequested={this.handleInitializeEditedDataRequest}
          onEditedDataChanged={this.props.onEditedDataChanged}
          onRowSelectionChanged={this.handleRowSelectionChange}
        />
      );
    });

    return (
      <Table size="sm" responsive striped bordered hover>
        <thead>
          <tr>
            {this.props.batchSelectionEnabled ? (
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={this.handleSelectAllRowsChange}
                  checked={this.state.selectAll}
                />
              </th>
            ) : (
              <></>
            )}
            {tableHeaders}
            {this.props.actionsEnabled ? (
              <th>
                {this.props.loading ? (
                  <Container className="p-0 text-center">
                    <Spinner animation="border" size="sm" />
                  </Container>
                ) : (
                  <></>
                )}
              </th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>{dataRows}</tbody>
      </Table>
    );
  }
}
