import React, { Component } from "react";
import FinancialAppClient from "../../clients/FinancialAppClient";
import { CRUDTable } from "../shared/CRUDTable";

export class RatesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: [],
      refreshingRatesInProgress: false,
    };
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.refreshRates();
  }

  refreshRates() {
    this.setState({
      refreshingRatesInProgress: true,
    });
    this.api.listCustomRates((rates, error) => {
      this.setState({
        refreshingRatesInProgress: false,
      });
      if (error) {
        alert("Failed to load rates: " + error.message);
      } else {
        this.setState({ rates: rates });
      }
    });
  }

  handleRateDeleteRequest = (rate, callback) => {
    if (window.confirm("Delete this rate permanently?")) {
      this.api.deleteCustomRate(rate.id, (response, error) => {
        if (error) {
          callback(false);
          alert("Failed to delete rate: " + error.message);
        } else {
          callback(true);
        }
      });
    } else {
      callback(false);
    }
  };

  handleRateUpdateRequest = (rate, callback) => {
    this.api.updateCustomRate(rate, (response, error) => {
      if (error) {
        callback(false);
        alert("Failed to update rate: " + error.message);
      } else {
        callback(true);
      }
    });
  };

  handleRateDelete = (rate) => {
    var newRates = this.state.rates;
    newRates.splice(
      this.state.rates.findIndex((element) => element.id === rate.id),
      1
    );
    this.setState({ rates: newRates });
  };

  handleRateUpdate = (newRate) => {
    var newRates = this.state.rates;
    let rateIndex = this.state.rates.findIndex((element) => element.id === newRate.id);
    newRates[rateIndex] = newRate;
    this.setState({ rates: newRates });
  };

  render() {
    return (
      <CRUDTable
        schema={{
          headers: [
            { title: "Name", sortingEnabled: false },
            { title: "Value", sortingEnabled: false },
            { title: "Currency", sortingEnabled: false },
            { title: "Date", sortingEnabled: false },
          ],
          rows: [
            { propertyName: "name", inputType: "text", placeholder: "Name" },
            {
              propertyName: "value",
              inputType: "number",
              placeholder: "Value",
            },
            {
              propertyName: "currency",
              inputType: "select",
              placeholder: "Currency",
              options: ["", "CAD", "USD", "EUR", "HUF"],
            },
            {
              propertyName: "date",
              inputType: "date",
              placeholder: "yyyy-mm-dd",
            },
          ],
        }}
        data={this.state.rates}
        actionsEnabled={true}
        editModeByDefault={false}
        onDataDeleteRequested={this.handleRateDeleteRequest}
        onDataUpdateRequested={this.handleRateUpdateRequest}
        onDataDeleted={this.handleRateDelete}
        onDataUpdated={this.handleRateUpdate}
        loading={this.state.refreshingRatesInProgress}
      />
    );
  }
}
