import React, { Component } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";
import { RatesTable } from "./RatesTable";
import { RateUpload } from "./RateUpload";

export class Rates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNewRateModal: false,
    };
    this.ratesTable = React.createRef();
  }

  handleCreateNewRateModalOpen = () => {
    this.setState({
      showCreateNewRateModal: true
    });
  }

  handleCreateNewRateModalClose = (ratesRefreshed) => {
    this.setState({
      showCreateNewRateModal: false
    });
    if (ratesRefreshed) {
      this.ratesTable.current.refreshRates();
    }
  }

  render() {
    return (
      <>
        <Container className="px-0">
          <Row>
            <Col>
              <DropdownButton title="Actions" align="end" className='float-end'>
                <Dropdown.Item onClick={this.handleCreateNewRateModalOpen}>Create new rate</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <RatesTable ref={this.ratesTable}/>
            </Col>
          </Row>
        </Container>
        <RateUpload
          show={this.state.showCreateNewRateModal}
          onHide={this.handleCreateNewRateModalClose}/>
      </>
    );
  }
}
