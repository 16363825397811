import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { CRUDTable } from "../shared/CRUDTable";
import FinancialAppClient from "../../clients/FinancialAppClient";

export class PatternUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      patterns: [this.createNewEmptyPattern()],
      editedPattern: this.createNewEmptyPattern(),
      uploadInProgress: false
    };
    this.CRUDTable = React.createRef();
    this.api = FinancialAppClient.getInstance();
  }

  componentDidMount() {
    this.PatternUpload();
  }

  PatternUpload() {
    this.api.listCategories((categories, error) => {
      if (error) {
        alert("Failed to list categories: "+error.message);
      } else {
        this.setState({ categories: categories });
      }
    });
  }

  getCategoryValues(category1, category2) {
    return this.state.categories
      .filter(
        (category) => {
        if (category1 === undefined) return true
        else if (category2 === undefined) return category.category1 === category1 && category.category2;
        else return category.category1 === category1 && category.category2 === category2 && category.category3;
      })
      .map((category) => {
        if (category1 === undefined) return category.category1;
        else if (category2 === undefined) return category.category2;
        else return category.category3;
      })
      .filter((item, i, self) => self.indexOf(item) === i) // Filter duplicates
      .sort();
  }

  createNewEmptyPattern() {
    return {
      id: Math.random() * Number.MAX_VALUE,
      pattern: "",
      description: "",
      category1: "Follow-up",
      category2: "",
      category3: ""
    };
  }

  handleEditedPatternChange = (pattern) => {
    this.setState({editedPattern: pattern});
  }

  handlePatternUpload = () => {
    this.setState({
      uploadInProgress: true
    });
    this.api.uploadPattern(this.state.editedPattern, (response, error) => {
      this.setState({
        uploadInProgress: false
      });
      if (error) {
        alert("Failed to upload pattern: "+error.message);
      } else {
        this.setState({
          patterns: [this.createNewEmptyPattern()],
          editedPattern: this.createNewEmptyPattern()
        });
        this.props.onHide(true);
      }
    });
  };

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={() => {this.props.onHide(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Create new pattern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CRUDTable
            schema={{
              headers: [
                {title: "Pattern", sortingEnabled: true},
                {title: "Description", sortingEnabled: false},
                {title: "Category 1", sortingEnabled: false},
                {title: "Category 2", sortingEnabled: false},
                {title: "Category 3", sortingEnabled: false},
              ],
              rows: [
                {propertyName: "pattern", inputType: "text", placeholder: "Pattern"},
                {propertyName: "description", inputType: "text", placeholder: "Description"},
                {
                  propertyName: "category1",
                  inputType: "select",
                  options: this.getCategoryValues(),
                  onValueChanged: (newCategory1Value, editedPattern) => {
                    const newCategory2 = this.getCategoryValues(newCategory1Value)[0];
                    const newCategory3 = this.getCategoryValues(newCategory1Value, newCategory2 ? newCategory2 : "")[0];
                    return {
                      ...editedPattern,
                      category1: newCategory1Value,
                      category2: newCategory2 ? newCategory2 : "",
                      category3: newCategory3 ? newCategory3 : ""
                    };
                  }
                },
                {
                  propertyName: "category2",
                  inputType: "select",
                  options: (editedData) => this.getCategoryValues(editedData.category1),
                  onValueChanged: (newCategory2Value, editedPattern) => {
                    const newCategory3 = this.getCategoryValues(editedPattern.category1, newCategory2Value)[0];
                    return {
                      ...editedPattern,
                      category2: newCategory2Value,
                      category3: newCategory3 ? newCategory3 : ""
                    };
                  }
                },
                {propertyName: "category3", inputType: "select", options: (editedData) => this.getCategoryValues(editedData.category1, editedData.category2)}
              ]}}
            data={this.state.patterns}
            actionsEnabled={false}
            editModeByDefault={true}
            onEditedDataChanged={this.handleEditedPatternChange}
            loading={this.props.loading}
            ref={this.CRUDTable}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' disabled={this.state.uploadInProgress} onClick={this.handlePatternUpload}>
            {this.state.uploadInProgress ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
