import React, { Component } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import {
  BsTrash,
  BsTrashFill,
  BsPencil,
  BsPencilFill,
  BsXSquare,
  BsXSquareFill,
  BsCheckSquare,
  BsCheckSquareFill,
} from "react-icons/bs";

export class CRUDTableRowActions extends Component {
  render() {
    if (this.props.updateInProgress || this.props.deleteInProgress) {
      return (
        <Container className='p-0 text-center'>
          <Spinner animation='border' size='sm' />
        </Container>
      );
    } else if (this.props.editMode) {
      return (
        <Container className='p-0'>
          <Row className='m-0 justify-content-space-between'>
            <Col className='p-1 text-center'>
              <span className='hover-icon'>
                <BsXSquareFill
                  className='hover-icon-on'
                  onClick={this.props.onCloseDataEditor}
                  size='22px'
                  style={{ cursor: "pointer" }}
                />
                <BsXSquare className='hover-icon-off' size='22px' style={{ cursor: "pointer" }} />
              </span>
            </Col>
            <Col className='p-1 text-center'>
              <span className='hover-icon'>
                <BsCheckSquareFill
                  className='hover-icon-on'
                  onClick={this.props.onDataUpdateRequested}
                  size='22px'
                  style={{ cursor: "pointer" }}
                />
                <BsCheckSquare
                  className='hover-icon-off'
                  size='22px'
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container className='p-0'>
          <Row className='m-0 justify-content-space-between'>
            <Col className='p-1 text-center'>
              <span className='hover-icon'>
                <BsPencilFill
                  className='hover-icon-on'
                  onClick={this.props.onOpenDataEditor}
                  size='22px'
                  style={{ cursor: "pointer" }}
                />
                <BsPencil className='hover-icon-off' size='22px' style={{ cursor: "pointer" }} />
              </span>
            </Col>
            <Col className='p-1 text-center'>
              <span className='hover-icon'>
                <BsTrashFill
                  className='hover-icon-on'
                  onClick={this.props.onDataDeleteRequested}
                  size='22px'
                  style={{ cursor: "pointer" }}
                />
                <BsTrash className='hover-icon-off' size='22px' style={{ cursor: "pointer" }} />
              </span>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}
